import { app } from "../pages/firebase";
import { getDatabase, onValue, ref, get, child } from "firebase/database";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import CSS from "../App.css"
function AdminSale() {
    let [clients, setClients] = useState([]);
    let [aumData, setAUMData] = useState({}); // Store AUM data for all clients
    const database = getDatabase(app);
    const navigate = useNavigate();

    useEffect(() => {
        const clientArray = [];
        const clientData = [];
        const clientRef = ref(database, "clientDirectory");
        onValue(clientRef, (snapshot) => {
            const data = snapshot.val();
            for (const client in data) {
                clientArray.push(data[client]);
            }
            for (let i = 0; i < clientArray.length; i++) {
                for (const client in clientArray[i]) {
                    clientData.push(clientArray[i][client]);
                }
            }
            setClients(clientData);
        });
    }, [database]);

    function bondsInvestment(bondData) {
        let bondValue = 0;
        for (const value in bondData) {
            const aum = parseFloat((bondData[value].bondAmount));
            if (!isNaN(aum) && bondData[value].isVisible === "true") {
                bondValue += aum;
            }
        }
        return bondValue;
    }

    function mutualFundInvestment(mfData) {
        let mfValue = 0;
        for (const value in mfData) {
            const amount = parseInt(mfData[value].aum);
            if (!isNaN(amount) && mfData[value].isVisible === "true") {
                mfValue += amount;
                // console.log(mfData[value].isVisible, mfData[value].folioNumber)
            }
        }
        return mfValue;
    }

    function fixedDepositInvestement(fdData) {
        let fdValue = 0;
        for (const value in fdData) {
            const purchasePrice = parseInt(fdData[value].fdAmount);
            if (!isNaN(purchasePrice) && fdData[value].isVisible === "true") {
                fdValue += purchasePrice;
            }
        }
        return fdValue;
    }

    useEffect(() => {
        async function fetchClientAUM(rm, PAN) {
            let directoryRef = ref(database, `clientDirectory`);
            const empRef = child(directoryRef, `${rm}`);
            const clientDirectoryRef = child(empRef, PAN);

            try {
                const snapshot = await get(clientDirectoryRef);
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    if (data.Investments) {
                        const bonds = bondsInvestment(data.Investments.Bonds);
                        const mf = mutualFundInvestment(data.Investments.MF);
                        const fd = fixedDepositInvestement(data.Investments.FD);
                        const net = bonds + mf + fd;
                        setAUMData((prevAUM) => ({
                            ...prevAUM,
                            [PAN]: net, // Store AUM per PAN
                        }));
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        clients.forEach((client) => {
            if (!aumData[client.clientPAN]) {
                fetchClientAUM(client.rmCode, client.clientPAN);
            }
        });
    }, [clients, database,aumData]);

    function redirectToClientData(client) {
        window.localStorage.setItem("Name", client.clientName);
        window.localStorage.setItem("PAN", client.clientPAN);
        window.localStorage.setItem("rm", client.rmCode);
        navigate("/client-data");
    }

    return (
        <>
            <div className="card-body">
                <p className="card-title mb-0">Clients</p>
                <div className="table-responsive">
                    <table className="table table-striped table-borderless">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>DOB</th>
                                <th>Email</th>
                                <th>PAN</th>
                                <th>RM</th>
                                <th>AUM</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((client) => (
                                <tr key={client.clientPAN}>
                                    <td>
                                    
                                     {/* eslint-disable-next-line */}
                                        <a className="redirect-page" onClick={() => redirectToClientData(client)}>
                                            {client.clientName}
                                        </a>
                                    </td>
                                    <td>{client.clientType}</td>
                                    <td>{client.clientDOB}</td>
                                    <td>{client.clientEmail}</td>
                                    <td>{client.clientPAN}</td>
                                    <td>{client.clientRM}</td>
                                    <td>{aumData[client.clientPAN] || "Loading..."}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default AdminSale;
