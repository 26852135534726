import React, { useState } from "react";
import { Link } from "react-router-dom";
import { app } from "../pages/firebase";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function SideBar() {
  const auth = getAuth(app);
  const navigate = useNavigate("")
  const handleLogout = async () => {
    try {
      window.localStorage.clear();
      await signOut(auth);
      navigate("/");
      console.log("User signed out successfully!");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  let isAdmin = window.localStorage.getItem("isAdmin");
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="icon-grid menu-icon" />
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <i className="icon-layout menu-icon" />
              <span className="menu-title">Employees</span>
              <i className="menu-arrow" />
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                {(isAdmin === "true" || "hr") ?
                  (<li className="nav-item">
                    <Link className="nav-link" to="/employee-directory">
                      Employee directory
                    </Link>
                  </li>) : null
                }
                <li className="nav-item">
                  <Link className="nav-link" to="#">
                    Attendance
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/leave-request">
                    Leave Requests
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="#">
                    Absence Trends
                  </Link>
                </li>
              </ul>
            </div>
          </li>


          <li className="nav-item">
            <Link className="nav-link" to="/sales">
              <i className="icon-bar-graph menu-icon" />
              <span className="menu-title">Sales</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/add-investments">
              <i className="icon-bar-graph menu-icon" />
              <span className="menu-title">Add Investements</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link onClick={handleLogout} className="nav-link" to="/">
              <i className="fa fa-sign-out" style={{ fontSize: 21 }} />
              <span style={{ marginLeft: 12 }} className="menu-title">
                Log Out
              </span>
            </Link>
          </li>
        </ul>
      </nav>
      <div className="theme-setting-wrapper">
        <div id="settings-trigger">
          <i className="ti-settings" />
        </div>
        <div id="theme-settings" className="settings-panel">
          <i className="settings-close ti-close" />
          <p className="settings-heading">SIDEBAR SKINS</p>
          <div className="sidebar-bg-options selected" id="sidebar-light-theme">
            <div className="img-ss rounded-circle bg-light border mr-3" />
            Light
          </div>
          <div className="sidebar-bg-options" id="sidebar-dark-theme">
            <div className="img-ss rounded-circle bg-dark border mr-3" />
            Dark
          </div>
          <p className="settings-heading mt-2">HEADER SKINS</p>
          <div className="color-tiles mx-0 px-4">
            <div className="tiles success" />
            <div className="tiles warning" />
            <div className="tiles danger" />
            <div className="tiles info" />
            <div className="tiles dark" />
            <div className="tiles default" />
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
