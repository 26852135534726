import React from "react";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import OpenSans from "../font/OpenSans-VariableFont_wdth,wght.ttf";
import DancingScript from "../font/DancingScript-VariableFont_wght.ttf";

// Register fonts
Font.register({
  family: "Dancing Script",
  src: DancingScript,
});
Font.register({
  family: "Open Sans",
  src: OpenSans,
});

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
    fontWeight: 600,
    fontFamily: "Open Sans",
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 16,
    fontFamily: "Open Sans",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 12,
  },
  flexItem: {
    flex: 1,
    marginRight: 12,
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: 12,
  },
  label: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: "bold",
    color: "#000000",
  },
  data: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: "normal",
    color: "#3E3E3E",
  },
  logo: {
    width: 100,
    height: "auto",
    alignSelf: "center",
    marginBottom: 16,
  },
  signature: {
    fontFamily: "Dancing Script",
    fontSize: 20,
    color: "#3E3E3E",
  },
});

// Get today's date
const tDate = new Date();
const year = tDate.getFullYear();
const month = tDate.getMonth() + 1;
const day = tDate.getDate();
let todayDate = `${day}-${month}-${year}`;

const MyDocument = ({ formData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.flexColumn}>
          <Image
            style={styles.logo}
            src="/assets/images/dimesnion-logo-s.png" // Correct path to the image in the public directory
          />
          <Text style={styles.title}>Leave Application Form</Text>
        </View>
        <View style={styles.flexContainer}>
          <View style={styles.flexItem}>
            <Text style={styles.subtitle}>
              <Text style={styles.label}>Employee’s Name: </Text>
              <Text style={styles.data}>{formData.name}</Text>
            </Text>
          </View>
          <View style={styles.flexItem}>
            <Text style={styles.subtitle}>
              <Text style={styles.label}>Designation: </Text>
              <Text style={styles.data}>{formData.designation}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.flexContainer}>
          <View style={styles.flexItem}>
            <Text style={styles.subtitle}>
              <Text style={styles.label}>Department: </Text>
              <Text style={styles.data}>{formData.department}</Text>
            </Text>
          </View>
          <View style={styles.flexItem}>
            <Text style={styles.subtitle}>
              <Text style={styles.label}>Employee Code: </Text>
              <Text style={styles.data}>{formData.employeeCode}</Text>
            </Text>
          </View>
        </View>
        <Text style={styles.subtitle}>
          <Text style={styles.label}>Leave: </Text>
          <Text style={styles.data}>{formData.leaveType}</Text>
        </Text>
        <Text style={styles.subtitle}>
          <Text style={styles.label}>Type of Leave: </Text>
          <Text style={styles.data}>{formData.type}</Text>
        </Text>
        <Text style={styles.subtitle}>
          <Text style={styles.label}>From Date: </Text>
          <Text style={styles.data}>{formData.fromDate}</Text>
        </Text>
        <Text style={styles.subtitle}>
          <Text style={styles.label}>To Date: </Text>
          <Text style={styles.data}>{formData.toDate}</Text>
        </Text>
        <Text style={styles.subtitle}>
          <Text style={styles.label}>Reason: </Text>
          <Text style={styles.data}>{formData.reason}</Text>
        </Text>
        <View style={styles.flexContainer}>
          <View style={styles.flexItem}>
            <Text style={styles.signature}>{formData.name}</Text>
          </View>
          <View style={styles.flexItem}>
            <Text style={styles.subtitle}>Date: {todayDate}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;