import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { app } from "../pages/firebase"
import { getDatabase, ref, onValue, child, get, set } from "firebase/database"
import { isVisible } from "@testing-library/user-event/dist/utils";
// import { useNavigate } from "react-router-dom";
function AddMF() {
    const navigate = useNavigate();
    const database = getDatabase(app)
    const [selectedPAN, setSelectedPAN] = useState('');
    let [clients, setClients] = useState([]);
    let [rmCode, setRMCode] = useState("")
    const [clientName, setClientName] = useState("")
    const [clientEmail, setClientEmail] = useState("")
    let [investmentDate, setInvestmentDate] = useState("")
    let [folioNumber, setFolioNumber] = useState("")
    let [amount, setAmount] = useState("")
    let [fundType, setFundType] = useState("")
    let [company, setCompany] = useState("");
    let [brokerage, setBrokerage] = useState("")
    const [aum, setAUM] = useState(0)
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedScheme, setSelectedScheme] = useState('');
    const [schemeOptions, setSchemeOptions] = useState([]);
    const companies = [
        "UTI",
        "Aditya Birla",
        "DSP",
        "HDFC",
        "ICICI",
        "HSBC",
        "SBI",
        "Axis",
        "Kotak",
        "Tata",
        "BOI",
        "Canara Rebbeco",
        "Edelweiss",
        "Bandhan",
        "White Oak",
        "PGIM",
        "Sundaram",
        "Franklin",
        "Motilal Oswal",
        "Mirae"

    ];

    const companySchemes = {
        "Mirae": [
            "Mirae Asset Healthcare Fund",
            "Mirae Asset Banking and Financial Services Fund",
            "Mirae Asset Liquid Fund",
            "Mirae Asset Nifty MidSmallcap400 Momentum Quality 100 E T F FoF",
            "Mirae Asset ELSS Tax Saver Fund",
            "Mirae Asset Multicap Fund",
            "Mirae Asset Large Cap Fund",
            "Mirae Asset Nifty 200 Alpha 30 E T F FoF",
            "Mirae Asset Mid Cap Fund",
            "Mirae Asset Nifty Smallcap 250 Momentum Quality 100 E T F FoF",
            "Mirae Asset Equity Savings Fund",
            "Mirae Asset Ultra Short Duration Fund",
            "Mirae Asset Low Duration Fund",
            "Mirae Asset Short Duration Fund",
            "Mirae Asset Corporate Bond Fund",
            "Mirae Asset Equity Allocator Fund of Fund",
            "Mirae Asset Overnight Fund",
            "Mirae Asset Focused Fund",
            "Mirae Asset Money Market Fund",
            "Mirae Asset Dynamic Bond Fund",
            "Mirae Asset Large & Midcap Fund",
            "Mirae Asset ESG Sector Leaders FoF",
            "Mirae Asset Aggressive Hybrid Fund",
            "Mirae Asset Arbitrage Fund",
            "Mirae Asset Nifty India Manufacturing E T F FoF",
            "Mirae Asset Multi Asset Allocation Fund",
            "Mirae Asset Great Consumer Fund",
            "Mirae Asset Banking And PSU Fund",
            "Mirae Asset Flexi Cap Fund",
            "Mirae Asset Balanced Advantage Fund",
            "Mirae Asset Nifty AAA PSU Bond Plus SDL Apr 2026 5050 Index Fund",
            "Mirae Asset Nifty SDL Jun 2028 Index Fund",
            "Mirae Asset CRISIL IBX Gilt Index April 2033 Index Fund",
            "Mirae Asset Nifty SDL Jun 2027 Index Fund",
            "Mirae Asset Nifty EV and New Age Automotive E T F",
            "Mirae Asset Global Electric & Autonomous Vehicles E T F FoF",
            "Mirae Asset Hang Seng TECH E T F FOF",
            "Mirae Asset NYSE FANG+ E T F FoF",
            "Mirae Asset S&P 500 Top 50 E T F FoF",
            "Mirae Asset Global X Artificial Intelligence & Technology E T F FoF"

        ],
        "Motilal Oswal": [
            "Motilal Oswal Nifty Bank Index Fund",
            "Motilal Oswal ELSS Tax Saver Fund",
            "Motilal Oswal Gold and Silver E T F FoF",
            "Motilal Oswal Liquid Fund",
            "Motilal Oswal Nifty 500 Momentum 50 Index",
            "Motilal Oswal Nifty 50 Index Fund",
            "Motilal Oswal Nifty Next 50 Index Fund",
            "Motilal Oswal Nifty Midcap 150 Index Fund",
            "Motilal Oswal Nifty Smallcap 250 Index Fund",
            "Motilal Oswal Nifty 200 Momentum 30 Index Fund",
            "Motilal Oswal Nifty Microcap 250 Index Fund",
            "Motilal Oswal S&P BSE Enhanced Value Index Fund",
            "Motilal Oswal S&P BSE Quality Index Fund",
            "Motilal Oswal S&P BSE Low Volatility Index Fund",
            "Motilal Oswal S&P BSE Financials ex Bank 30 Index Fund",
            "Motilal Oswal Multi Cap Fund",
            "Motilal Oswal Nifty 500 Fund",
            "Motilal Oswal Midcap Fund",
            "Motilal Oswal Small Cap Fund",
            "Motilal Oswal Large Cap Fund",
            "Motilal Oswal Ultra Short Term Fund",
            "Motilal Oswal Focused Fund",
            "Motilal Oswal Large and Midcap Fund",
            "Motilal Oswal Multi Asset Fund",
            "Motilal Oswal Business Cycle Fund",
            "Motilal Oswal Flexi Cap Fund",
            "Motilal Oswal 5 Year G Sec FOF",
            "Motilal Oswal Nifty India Defence Index Fund",
            "Motilal Oswal Manufacturing Fund",
            "Motilal Oswal Balance Advantage Fund",
            "Motilal Oswal Asset Allocation Passive FoF Conservative",
            "Motilal Oswal Asset Allocation Passive FoF Aggressive",
            "Motilal Oswal S&P 500 Index Fund",
            "Motilal Oswal Nasdaq 100 FOF",
            "Motilal Oswal Equity Hybrid Fund",
            "Motilal Oswal MSCI EAFE Top 100 Select Index Fund"

        ],
        "Franklin": [
            "Franklin Build India Fund",
            "Franklin India ELSS Tax Saver Fund",
            "Franklin India Technology Fund",
            "Franklin India Liquid Fund Super I P",
            "Franklin India NSE Nifty 50 Index Fund",
            "Franklin India Multi Cap Fund",
            "Franklin India Prima Fund",
            "Franklin India Smaller Companies Fund",
            "Franklin India Bluechip Fund",
            "Franklin India Equity Advantage Fund",
            "Franklin India Equity Savings Fund",
            "Franklin India Ultra Short Duration Fund",
            "Franklin India Medium to Long Duration Fund",
            "Franklin India Money Market Fund",
            "Franklin India Corporate Debt Fund Plan A",
            "Franklin India Focused Equity Fund",
            "Franklin India Overnight Fund",
            "Franklin India Multi Asset Solution Fund",
            "Franklin India Feeder Franklin US Opp",
            "Franklin India Flexi Cap Fund",
            "Franklin India Opportunities Fund",
            "Franklin India Dynamic Asset Allocation Fund of Funds",
            "Franklin India Feeder Franklin European Growth Fund",
            "Franklin India Banking & PSU Debt Fund",
            "Franklin India Equity Hybrid Fund",
            "Franklin India Government Securities Fund",
            "Franklin India Floating Rate Fund",
            "Franklin India Debt Hybrid Fund",
            "Franklin Asian Equity Fund",
            "Franklin India Balanced Advantage Fund",
            "Franklin India Credit Risk Fund",
            "Franklin India ST Income Plan",
            "Franklin India Low Duration (G & HY)",
            "Franklin India Ultra Short Bond Super Ins",
            "Franklin India Income Oppt Fund",
            "Franklin India Dynamic Accrual Fund",
            "Franklin India Life Stage FOF THE 40S Plan",
            "Franklin India Life Stage FOF THE 50+S Plan",
            "Franklin India Life Stage FOF 50s Plus F R",
            "Franklin India Pension Plan",
            "Franklin India Life Stage FOF THE 20S Plan",
            "Franklin India Life Stage FOF THE 30S Plan"

        ],
        "Sundaram": [
            "Sundaram Financial Services Opp Fund",
            "Sundaram Infrastucture Advantage Fund",
            "Sundaram Liquid Fund",
            "Sundaram Nifty 100 Equal Weight Fund",
            "Sundaram ELSS Tax Saver Fund",
            "Sundaram Multi Cap Fund",
            "Sundaram Small Cap Fund",
            "Sundaram Mid Cap Fund",
            "Sundaram Large Cap Fund",
            "Sundaram Equity Savings Fund",
            "Sundaram Banking & PSU Debt Fund",
            "Sundaram Short Duration Fund",
            "Sundaram Medium Term Bond Fund",
            "Sundaram Focused Fund",
            "Sundaram Money Market Fund",
            "Sundaram Corporate Bond Fund",
            "Sundaram Ultra Short Duration Fund",
            "Sundaram Low Duration Fund",
            "Sundaram Large and Mid Cap Fund",
            "SUNDARAM DIVIDEND YIELD FUND",
            "Sundaram Services Fund",
            "Sundaram Consumption Fund",
            "Sundaram Balanced Advantage Fund",
            "Sundaram Arbitrage Fund",
            "Sundaram Global Brand Fund",
            "Sundaram Debt Oriented Hybrid Fund",
            "Sundaram Multi Asset Allocation Fund",
            "Sundaram Business Cycle Fund",
            "Sundaram Flexi Cap Fund",
            "Sundaram Aggressive Hybrid Fund",
            "Sundaram Overnight Fund",
            "Sundaram Diversified Equity",
            "Sundaram Short Term Credit Risk Fund"

        ],
        "PGIM": [
            "PGIM India Liquid Fund",
            "PGIM India ELSS Tax Saver Fund",
            "PGIM India Small Cap Fund",
            "PGIM India Multi Cap Fund",
            "PGIM India Large Cap Fund",
            "PGIM India Mid Cap Opp Fund",
            "PGIM India Dynamic Bond Fund",
            "PGIM India Equity Savings Fund",
            "PGIM India Banking & PSU Debt Fund",
            "PGIM India Money Market Fund",
            "PGIM India Corporate Bond Fund",
            "PGIM India Ultra Short Duration Fund",
            "PGIM India Short Duration Fund",
            "PGIM India CRISIL IBX Gilt Index - Apr 2028",
            "PGIM India Large and Mid Cap Fund",
            "PGIM India Overnight Fund",
            "PGIM India Global Equity Opportunities Fund",
            "PGIM India Flexi Cap Fund",
            "PGIM India Global Select Real Estate Securities Fund of Fund",
            "PGIM India Gilt Fund",
            "PGIM India Arbitrage Fund",
            "PGIM India Retirement Fund",
            "PGIM India Emerging Markets Equity Fund",
            "PGIM India Balanced Advantage Fund",
            "PGIM India Hybrid Equity Fund"

        ],
        "White Oak": [
            "WhiteOak Capital Banking & Financial Services Fund",
            "WhiteOak Capital Digital Bharat Fund",
            "WhiteOak Capital Pharma and Healthcare Fund",
            "WhiteOak Capital Liquid Fund",
            "WhiteOak Capital ELSS Tax Saver Fund",
            "WhiteOak Capital Multi Cap Fund",
            "WhiteOak Capital Large Cap Fund",
            "WhiteOak Capital Mid Cap Fund",
            "WhiteOak Capital Ultra Short Duration Fund",
            "WhiteOak Capital Large & Mid Cap Fund",
            "WhiteOak Capital Balanced Hybrid Fund",
            "WhiteOak Capital Flexi Cap Fund",
            "WhiteOak Capital Arbitrage Fund",
            "WhiteOak Capital Special Opportunities Fund",
            "WhiteOak Capital Balanced Advantage Fund",
            "WhiteOak Capital Multi Asset Allocation Fund"

        ],
        "Bandhan": [
            "Bandhan Infrastructure Fund",
            "Bandhan Nifty Bank Index Fund",
            "Bandhan ELSS Tax saver Fund",
            "Bandhan Financial Services Fund",
            "Bandhan BSE Healthcare Index Fund",
            "Bandhan Liquid Fund",
            "Bandhan Nifty Midcap 150 Index Fund",
            "Bandhan Nifty Total Market Index Fund",
            "Bandhan Nifty 50 Index Fund",
            "Bandhan Nifty Smallcap 250 Index Fund",
            "Bandhan Nifty 100 Index Fund",
            "Bandhan Nifty IT Index Fund",
            "Bandhan Nifty Alpha 50 Index Fund",
            "Bandhan Nifty200 Momentum 30 Index Fund",
            "Bandhan Nifty100 Low Volatility 30 Index Fund",
            "Bandhan MULTI CAP FUND",
            "Bandhan Small Cap Fund",
            "Bandhan Midcap Fund",
            "Bandhan Large Cap Fund",
            "Bandhan Equity Savings Fund",
            "Bandhan Crisil IBX Gilt April 2032 Index Fund",
            "Bandhan Credit Risk Fund",
            "Bandhan Banking & PSU Debt Fund",
            "Bandhan Ultra Short Term Fund",
            "Bandhan Bond Fund Income Plan",
            "Bandhan CRISIL IBX 90:10 SDL Plus Gilt-Sep 2027 Index Fund",
            "Bandhan Bond Fund MT Plan",
            "Bandhan Corporate Bond Fund",
            "Bandhan Low Duration Fund",
            "Bandhan Long Duration Fund",
            "Bandhan All Seasons Bond Fund",
            "Bandhan Overnight Fund",
            "Bandhan Money Manager Fund",
            "Bandhan Bond Fund ST Plan",
            "Bandhan CRISIL IBX Gilt June 2027 Index Fund",
            "Bandhan Crisil IBX 90:10 SDL Plus Gilt- November 2026 Index Fund",
            "Bandhan Core Equity Fund",
            "Bandhan Crisil IBX Gilt April 2026 Index Fund",
            "Bandhan Dynamic Bond Fund",
            "Bandhan Focused Equity Fund",
            "Bandhan Sterling Value Fund",
            "Bandhan Transportation and Logistics Fund",
            "Bandhan Flexi Cap Fund",
            "Bandhan US Equity Fund OF Fund",
            "Bandhan Hybrid Equity Fund",
            "Bandhan Innovation Fund",
            "Bandhan Asset Allocation FoF Moderate",
            "Bandhan Retirement Fund",
            "Bandhan G Sec Inv Plan",
            "Bandhan Floating Rate Fund",
            "Bandhan Asset Allocation FoF Conservative",
            "Bandhan CRISIL IBX Gilt April 2028 Index Fund",
            "Bandhan G Sec Constant Maturity Plan",
            "Bandhan Business Cycle Fund",
            "Bandhan Regular Savings Fund",
            "Bandhan Asset Allocation FoF Aggressive",
            "Bandhan Balanced Advantage Fund",
            "Bandhan Multi Asset Allocation Fund",
            "Bandhan Arbitrage Fund",
            "Bandhan Crisil IBX 90:10 SDL Plus Gilt- April 2032 Index Fund",
            "Bandhan US Treasury Bond 0-1 year FoF"

        ],
        "Edelweiss": [
            "Edelweiss ELSS Tax Saver Fund",
            "Edelweiss Technology Fund",
            "Edelweiss Liquid Fund",
            "Edelweiss Gold and Silver E T F FoF",
            "Edelweiss Nifty Alpha Low Volatility 30 Index Fund",
            "Edelweiss Nifty Midcap150 Momentum 50 Index Fund",
            "Edelweiss Nifty Next 50 Index Fund",
            "Edelweiss Nifty 100 Quality 30 Index Fund",
            "Edelweiss MSCI India Domestic & World Healthcare 45 Index Fund",
            "Edelweiss Nifty 50 Index Fund",
            "Edelweiss NIFTY Large Mid Cap 250 Index Fund",
            "Edelweiss Nifty Smallcap 250 Index Fund",
            "Edelweiss Recently Listed IPO Fund",
            "Edelweiss Small Cap Fund",
            "Edelweiss Multi Cap Fund",
            "Edelweiss Large Cap Fund",
            "Edelweiss Mid Cap Fund",
            "Edelweiss Equity Savings Fund",
            "Edelweiss Overnight Fund",
            "Edelweiss Focused Fund",
            "Edelweiss CRISIL IBX 50:50 Gilt Plus SDL Short Duration Index Fund",
            "Edelweiss BHARAT Bond FOF April 2025",
            "Edelweiss CRISIL IBX 5050 Gilt Plus SDL Jun 2027 Index Fund",
            "Edelweiss NIFTY PSU Bond Plus SDL Index Fund 2027",
            "Edelweiss BHARAT Bond FOF April 2023",
            "Edelweiss NIFTY PSU Bond Plus SDL Index Fund 2026",
            "Edelweiss CRISIL PSU Plus SDL 50:50 Oct 2025 Index Fund",
            "Edelweiss BHARAT Bond E T F FOF April 2032",
            "Edelweiss BHARAT Bond FOF April 2031",
            "Edelweiss CRISIL IBX 50:50 Gilt Plus SDL April 2037 Index Fund",
            "Edelweiss CRISIL IBX 50:50 Gilt Plus SDL Sep 2028 Index Fund",
            "Edelweiss BHARAT Bond E T F FOF - April 2033",
            "Edelweiss Money Market Fund",
            "Edelweiss BHARAT Bond FOF April 2030",
            "Edelweiss Banking and PSU Debt Fund",
            "Edelweiss Large & Mid Cap Fund",
            "Edelweiss US Technology Equity Fund of Fund",
            "Edelweiss Multi Asset Allocation Fund",
            "Edelweiss US Value Equity Offshore Fund",
            "Edelweiss Europe Dynamic Equity Off shore Fund",
            "Edelweiss Greater China Equity Off shore Fund",
            "Edelweiss Asean Equity Off shore Fund",
            "Edelweiss Flexi Cap Fund",
            "Edelweiss Govt. Sec Fund",
            "Edelweiss Arbitrage Fund",
            "Edelweiss Business Cycle Fund",
            "Edelweiss Emerging Markets Opp Equity Offshore Fund",
            "Edelweiss Aggressive Hybrid Fund",
            "Edelweiss Balanced Advantage Fund"

        ],
        "Canara rebbeco": [
            "Canara Robeco Infrastructure Fund",
            "Canara Robeco Liquid Fund",
            "Canara Robeco Multi Cap Fund",
            "Canara Robeco Small Cap Fund",
            "Canara Robeco Mid Cap Fund",
            "Canara Robeco Bluechip Equity Fund",
            "Canara Robeco Emerging equities",
            "Canara Robeco Value Fund",
            "Canara Robeco Corporate Bond Fund",
            "Canara Robeco Savings Fund",
            "Canara Robeco Focused Equity Fund",
            "Canara Robeco Ultra Short Term Fund",
            "Canara Robeco Income Fund",
            "Canara Robeco Short Duration Fund",
            "Canara Robeco Dynamic Bond Fund",
            "Canara Robeco Balanced Advantage Fund",
            "Canara Robeco Conservative Hybrid Fund",
            "Canara Robeco Banking and PSU Debt Fund",
            "Canara Robeco Gilt",
            "Canara Robeco Manufacturing Fund",
            "Canara Robeco ELSS Tax Saver Fund",
            "Canara Robeco Consumer Trends Fund",
            "Canara Robeco Flexi Cap Fund",
            "Canara Robeco Equity Hybrid Fund",
            "Canara Robeco Overnight Fund"

        ],
        "UTI": [
            "UTI Value Fund",
            "UTI Overnight Fund",
            "UTI Corporate Bond Fund",
            "UTI Conservative Hybrid Fund",
            "UTI Arbitrage Fund",
            "UTI MNC Fund",
            "UTI Transportation & Logistics",
            "UTI India Consumer Fund",
            "UTI Childrens Equity Fund",
            "UTI Retirement Fund",
            "UTI Flexi Cap Fund",
            "UTI Mid Cap Fund",
            "UTI Dynamic Bond Fund",
            "UTI Ultra Short Duration Fund",
            "UTI Short Duration Fund, Ins",
            "UTI Money Market Fund",
            "UTI Medium to Long Duration Fund",
            "UTI CRISIL SDL Maturity April 2033 Index Fund",
            "UTI CRISIL SDL Maturity June 2027 Index Fund",
            "UTI Gilt Fund",
            "UTI Aggressive Hybrid Fund",
            "UTI Multi Asset Allocation Fund",
            "UTI Innovation Fund",
            "UTI Dividend Yield Plan",
            "UTI Low Duration Fund Inst",
            "UTI Banking & PSU Fund",
            "UTI Childrens Hybrid Fund",
            "UTI Silver E T F FoF",
            "UTI Large & Mid Cap Fund",
            "UTI Focused Fund",
            "UTI Nifty 50 Index Fund",
            "UTI Sensex Index Fund",
            "UTI S&P BSE Low Volatility Index Fund",
            "UTI Nifty Next 50 Index Fund",
            "UTI Nifty200 Quality 30 Index Fund",
            "UTI Nifty Private Bank Index Fund",
            "UTI Nifty 500 Value 50 Index Fund",
            "UTI NIFTY 50 Equal Weight Index Fund",
            "UTI S&P BSE Housing Index Fund",
            "UTI Gold E T F FoF",
            "UTI Nifty Midcap 150 Quality 50 Index Fund",
            "UTI Nifty200 Momentum 30 Index Fund",
            "UTI Banking and Financial Services Fund",
            "UTI ELSS Tax Saver Fund",
            "UTI Healthcare Fund",
            "UTI Infrastructure Fund",
            "UTI Large Cap Fund",
            "UTI Small Cap Fund",
            "UTI Equity Savings Fund",
            "UTI Medium Duration Fund",
            "UTI Credit Risk Fund",
            "UTI Nifty SDL Plus AAA PSU Bond Apr 2026 75:25 Index Fund",
            "UTI Nifty SDL Plus AAA PSU Bond Apr 2028 75:25 Index Fund",
            "UTI Long Duration Fund",
            "UTI Gilt Fund, with 10 year Constant Duration",
            "UTI Floater Fund",
            "UTI Balanced Advantage Fund",
            "UTI Liquid Fund",
            "UTI Quarterly Interval Fund, - I"
        ],
        "Aditya Birla": [
            "Aditya Birla SL Active Debt MM FoF (G)",
            "Aditya Birla SL Pure Value Fund",
            "Aditya Birla SL Financial Planning FOF Aggressive Plan",
            "Aditya Birla SL Equity Hybrid 95 Fund",
            "Aditya Birla SL Balanced Advantage Fund",
            "Aditya Birla SL Asset Allocator FoF",
            "Aditya Birla SL Multi - Index FoF",
            "Aditya Birla SL Multi Asset Allocation Fund",
            "Aditya Birla SL India Gen Next Fund",
            "Aditya Birla SL Silver E T F FOF",
            "Aditya Birla SL International Equity Plan A",
            "Aditya Birla SL Global Excellence Equity Fund Of Fund",
            "Aditya Birla SL Global Emerging Opportunities Fund",
            "Aditya Birla SL Flexi Cap Fund",
            "Aditya Birla SL Frontline Equity Fund",
            "Aditya Birla SL Mid Cap Fund Plan",
            "Aditya Birla SL Multi Cap Fund",
            "Aditya Birla SL Dividend Yield Fund",
            "Aditya Birla SL Nifty SDL Sep 2025 Index Fund",
            "Aditya Birla SL Government Securities Fund",
            "Aditya Birla SL Government Securities Fund (G)Instant",
            "Aditya Birla SL Short Term Fund",
            "Aditya Birla SL CRISIL IBX Gilt - April 2026 Index Fund",
            "Aditya Birla SL CRISIL IBX 60: 40 SDL + AAA PSU - Apr 2027 Index Fund",
            "Aditya Birla SL Banking & PSU Debt Fund",
            "Aditya Birla SL Floating Rate Fund",
            "Aditya Birla SL Financial Planning FOF Conservative",
            "Aditya Birla SL Arbitrage Fund",
            "Aditya Birla SL ESG Integration Strategy Fund",
            "Aditya Birla SL Business Cycle Fund",
            "Aditya Birla SL Special Opportunities Fund",
            "Aditya Birla SL PSU Equity Fund",
            "Aditya Birla SL Manufacturing Equity Fund",
            "Aditya Birla SL Transportation and Logistics Fund",
            "Aditya Birla SL CRISIL IBX Gilt June 2027 Index Fund",
            "Aditya Birla SL Income Fund",
            "Aditya Birla SL CRISIL IBX SDL Jun 2032 Index Fund",
            "Aditya Birla SL Nifty SDL Plus PSU Bond Sep 2026 60: 40 Index Fund",
            "Aditya Birla SL CRISIL IBX 60: 40 SDL + AAA PSU Apr 2026 Index Fund",
            "Aditya Birla SL CRISIL IBX Gilt Apr 2028 Index Fund",
            "Aditya Birla SL CRISIL IBX Gilt Apr 2029 Index Fund",
            "Aditya Birla SL CRISIL IBX Gilt Apr 2033 Index Fund",
            "Aditya Birla SL Nifty SDL Sep 2027 Index Fund",
            "Aditya Birla SL Nifty SDL Apr 2027 Index Fund",
            "Aditya Birla SL CRISIL IBX 50: 50 Gilt Plus SDL Apr 2028 Index",
            "Aditya Birla SL Credit Risk Fund",
            "Aditya Birla SL Low Duration Fund",
            "Aditya Birla SL MNC Fund",
            "Aditya Birla SL Quant Fund",
            "Aditya Birla SL Nifty India Defence Index Fund",
            "Aditya Birla SL Retirement Fund 50s Plan",
            "Aditya Birla SL Retirement Fund 50s Plus Debt Plan",
            "Aditya Birla SL Retirement Fund 30s Plan",
            "Aditya Birla SL Retirement Fund 40s Plan",
            "Aditya Birla SL Financial Planning FOF Moderate",
            "Aditya Birla SL Dynamic Bond Fund",
            "Aditya Birla SL Equity Savings Fund",
            "Aditya Birla SL Equity Advantage Fund",
            "Aditya Birla SL Focused Fund",
            "Aditya Birla SL Savings Fund",
            "Aditya Birla SL Liquid Fund",
            "Aditya Birla SL Nifty 50 Equal Weight Index Fund",
            "Aditya Birla SL Nifty Next 50 Index Fund",
            "Aditya Birla SL Nifty 50 Index Fund",
            "Aditya Birla SL Gold Fund",
            "Aditya Birla SL Nifty Midcap 150 Index Fund",
            "Aditya Birla SL Nifty Smallcap 50 Index Fund",
            "Aditya Birla SL Infrastructure Fund",
            "Aditya Birla SL Digital India Fund",
            "Aditya Birla SL Pharma & Healthcare Fund",
            "Aditya Birla SL Banking and Financial Services Fund",
            "Aditya Birla SL ELSS Tax Saver Fund (ELSS U/S 80C of IT ACT)",
            "Aditya Birla SL Small Cap Fund",
            "Aditya Birla SL Money Manager Fund",
            "Aditya Birla SL CRISIL IBX 60: 40 SDL + AAA PSU - Apr 2025 Index Fund",
            "Aditya Birla SL Medium Term Plan Aditya Birla SL CRISIL IBX SDL Sep 2028 Index Fund",
            "Aditya Birla SL Corporate Bond Fund",
            "Aditya Birla SL Long Duration Fund",
            "Aditya Birla SL Regular Savings Fund",
            "Aditya Birla SL CRISIL IBX AAA Jun 2023 Index Fund",
            "Aditya Birla SL Overnight Fund",
            "Aditya Birla SL US Treasury 1-3 year Bond E T F FoF",
            "Aditya Birla SL CEF Global Agri",
            "Aditya Birla SL Nasdaq 100 FOF ",
            "Aditya Birla SL Tax Plan (ELSS U/S 80C of IT ACT) ",
            "Aditya Birla SL US Treasury 3-10 year Bond E T F FoF Aditya Birla SL CRISIL IBX AAA Mar 2024 Index Fund",
            "Aditya Birla SL Bal Bhavishya Yojna"
        ],
        "DSP": [
            "DSP Strategic Bond Fund (G)",
            "DSP Overnight Fund",
            "DSP Banking & PSU Debt Fund",
            "DSP Multi Asset Allocation Fund",
            "DSP Arbitrage Fund",
            "DSP Global Allocation Fund",
            "DSP Global Innovation Fund of Fund",
            "DSP Flexi Cap Fund",
            "DSP Mid cap Fund",
            "DSP Value Fund",
            "DSP Ultra Short Fund",
            "DSP Gilt Fund",
            "DSP 10Y G Sec Fund",
            "DSP Equity & Bond Fund",
            "DSP Equity Saving Fund",
            "DSP Savings Fund",
            "DSP Nifty SDL Plus G-Sec Jun 2028 30: 70 Index Fund",
            "DSP CRISIL SDL Plus G-Sec Apr 2033 50: 50 Index Fund",
            "DSP Low Duration Fund",
            "DSP Dynamic Asset Allocation",
            "DSP Natural Resources & New Energy",
            "DSP Quant Fund",
            "DSP World Gold FoF",
            "DSP World Energy Fund",
            "DSP World Mining Fund",
            "DSP US Flexible Equity Fund",
            "DSP Multicap Fund",
            "DSP Equity Opportunities Fund",
            "DSP Focus Fund",
            "DSP Liquidity Fund",
            "DSP Banking & Financial Services Fund",
            "DSP Gold E T F Fund of Fund",
            "DSP Nifty 50 Equal Weight Index Fund",
            "DSP Nifty 50 Index Fund",
            "DSP Nifty Top 10 Equal Weight Index Fund",
            "DSP Nifty Midcap 150 Quality 50 Index Fund",
            "DSP Nifty Next 50 Index Fund",
            "DSP Nifty Smallcap250 Quality 50 Index Fund",
            "DSP Nifty Bank Index Fund",
            "DSP Healthcare Fund",
            "DSP ELSS Tax Saver Fund",
            "DSP India T.I.G.E.R. Fund",
            "DSP Top 100 Equity Fund",
            "DSP Small Cap Fund",
            "DSP Short Term Fund",
            "DSP Bond Fund Ret",
            "DSP Nifty SDL Plus G-Sec Sep 2027 50: 50 Index Fund",
            "DSP Corporate Bond Fund",
            "DSP Floater Fund",
            "DSP Regular Savings Fund",
            "DSP Credit Risk Fund",
            "DSP US Treasury FoF",
            "DSP World Agriculture Fund"
        ],
        "HDFC": [
            "HDFC Capital Builder Value Fund",
            "HDFC Ultra Short Term Fund",
            "HDFC Credit Risk Debt Fund",
            "HDFC Banking and PSU Debt Fund",
            "HDFC Hybrid Debt Fund",
            "HDFC Dynamic PE Ratio Fund of FundsPlan A",
            "HDFC Hybrid Equity Fund",
            "HDFC Childrens Gift Fund",
            "HDFC Manufacturing Fund",
            "HDFC Non-Cyclical Consumer Fund",
            "HDFC Retirement Savings Fund Hybrid Debt",
            "HDFC Flexi Cap Fund",
            "HDFC Silver E T F FoF",
            "HDFC Equity Savings Fund",
            "HDFC Dividend Yield Fund",
            "HDFC Focused 30 Fund",
            "HDFC Money Market Fund",
            "HDFC Nifty G-sec Dec 2026 Index",
            "HDFC Gilt Fund",
            "HDFC Small Cap Fund",
            "HDFC Multi Cap Fund",
            "HDFC Nifty G-Sec Sep 2032 Index",
            "HDFC NIFTY G-Sec Apr 2029 Index Fund",
            "HDFC NIFTY G-Sec Jun 2036 Index Fund",
            "HDFC Long Duration Debt Fund",
            "HDFC Floating Rate Debt Fund",
            "HDFC Multi Asset Fund",
            "HDFC Business Cycle Fund",
            "HDFC Children Gift Invest",
            "HDFC Transportation and Logistics Fund",
            "HDFC Short Term Debt Fund",
            "HDFC Nifty G-Sec Jun 2027 Index",
            "HDFC Nifty SDL Oct 2026 Index Fund",
            "HDFC NIFTY SDL Plus G-Sec Jun 2027 40:60 Index Fund",
            "HDFC Income Fund",
            "HDFC Corporate Bond Fund",
            "HDFC Low Duration Fund",
            "HDFC Balanced Advantage Fund",
            "HDFC ASSET ALLOCATOR FoF",
            "HDFC Arbitrage Fund WP",
            "HDFC MNC Fund",
            "HDFC Retirement Savings Fund Equity Plan",
            "HDFC Retirement Savings Fund Hybrid Equity",
            "HDFC Top 100 Fund",
            "HDFC Dynamic Debt Fund",
            "HDFC Overnight Fund",
            "HDFC Index Fund-NIFTY 50 Plan",
            "HDFC NIFTY200 Momentum 30 Index Fund",
            "HDFC NIFTY100 Low Volatility 30 Index Fund",
            "HDFC NIFTY next 50 Index Fund",
            "HDFC NIFTY Midcap 150 Index Fund",
            "HDFC NIFTY Smallcap 250 Index Fund",
            "HDFC BSE 500 Index Fund",
            "HDFC Liquid Fund",
            "HDFC Index Fund - BSE SENSEX Plan",
            "HDFC Gold Fund",
            "HDFC NIFTY Realty Index Fund",
            "HDFC NIFTY 50 Equal weight Index Fund",
            "HDFC Nifty 100 Equal Weight Index Fund",
            "HDFC Nifty500 Multicap 50:25:25 Index Fund",
            "HDFC NIFTY 100 Index Fund",
            "HDFC Infrastructure Fund",
            "HDFC Banking and Financial Services Fund",
            "HDFC Pharma and Healthcare Fund",
            "HDFC ELSS Tax saver",
            "HDFC Technology Fund",
            "HDFC Housing Opp Fund",
            "HDFC Mid Cap Opportunities Fund",
            "HDFC Large And Mid Cap Fund",
            "HDFC Medium Term Debt Fund",
            "HDFC Nifty G-Sec July 2031 Index",
            "HDFC Arbitrage Fund Ret",
            "HDFC Defence Fund",
            "HDFC Developed World Indexes Fund OF Fund",
            "HDFC LongTerm Advantage Fund",
            "HDFC FMP 1204D December 2022",
            "HDFC FMP 1204D December 2022",
            "HDFC FMP 2638D February 2023 Sr 47",
            "HDFC FMP 1359D September 2022",
            "HDFC FMP 1269D March 2023",
            "HDFC FMP 1204D December 2022"
        ],
        "ICICI": [
            "ICICI Pru Dividend Yield Equity Fund",
            "ICICI Pru Value Discovery",
            "ICICI Pru Focused Equity Fund",
            "ICICI Pru Overnight Fund",
            "ICICI Pru Nifty SDL Dec 2028 Index Fund",
            "ICICI Pru Nifty SDL Sep 2027 Index Fund",
            "ICICI Pru Corporate Bond Fund",
            "ICICI Pru Equity & Debt Fund",
            "ICICI Pru Balanced Advantage Fund",
            "ICICI Pru Passive Multi Asset Fund OF Fund",
            "ICICI Pru FMCG Plan",
            "ICICI Pru Exports and Services Fund",
            "ICICI Pru Bharat Consumption Fund",
            "ICICI Pru MNC Fund",
            "ICICI Pru Child Care Gift Plan",
            "ICICI Pru Retirement Fund Hybrid Aggressive Plan",
            "ICICI Pru Retirement Fund Pure Equity Plan",
            "ICICI Pru Thematic Advantage Fund",
            "ICICI Pru Ultra Short Term Fund",
            "ICICI Pru Bluechip Fund",
            "ICICI Pru Multicap Fund",
            "ICICI Pru Short Term Fund",
            "ICICI Pru Nifty PSU Bond Plus SDL Sep 2027 40:60 Index Fund",
            "ICICI Pru Banking and PSU Debt Fund",
            "ICICI Pru Equity Arbitrage Fund",
            "ICICI Pru Business Cycle Fund",
            "ICICI Pru Quant Fund",
            "ICICI Pru PSU Equity Fund",
            "ICICI Pru Housing Opp Fund",
            "ICICI Pru Transportation and Logistics Fund",
            "ICICI Pru Commodities Fund",
            "ICICI Pru Energy Opp Fund",
            "ICICI Pru Innovation Fund",
            "ICICI Pru India Equity FOF",
            "ICICI Pru All Seasons Bond Fund",
            "ICICI Pru Equity Savings Fund",
            "ICICI Pru Medium Term Bond",
            "ICICI Pru Debt Management Fund",
            "ICICI Pru Bond Fund",
            "ICICI Pru Nifty G-Sec Dec 2030 Index Fund",
            "ICICI Pru Savings Fund",
            "ICICI Pru Gilt Fund",
            "ICICI Pru Multi Asset Fund",
            "ICICI Pru Asset Allocator Fund (FOF)",
            "ICICI Pru Manufacturing Fund",
            "ICICI Pru India Opportunities Fund",
            "ICICI Pru ESG Exclusionary Strategy Fund",
            "ICICI Pru SILVER E T F FoF",
            "ICICI Pru Retirement Fund Hybrid Conservative Plan",
            "ICICI Pru Retirement Fund Pure Debt Plan",
            "ICICI Pru Flexicap Fund",
            "ICICI Pru Large & Mid Cap Fund",
            "ICICI Pru Passive Strategy Fund",
            "ICICI Pru Liquid Fund",
            "ICICI Pru Nifty LargeMidcap 250 Index Fund",
            "ICICI Pru Regular Gold Savings",
            "ICICI Pru Nifty 200 Momentum 30 Index Fund",
            "ICICI Pru Nifty Midcap 150 Index Fund",
            "ICICI Pru BHARAT 22 FOF",
            "ICICI Pru Nifty Bank Index Fund",
            "ICICI Pru Nifty50 Value 20 Index Fund",
            "ICICI Pru Nifty 50 Index Fund",
            "ICICI Pru Nifty Next 50 Index Fund",
            "ICICI Pru BSE Sensex Index Fund",
            "ICICI Pru Nifty50 Equal Weight Index Fund",
            "ICICI Pru Nifty Auto Index Fund",
            "ICICI Pru Nifty 100 Low Vol 30 E T F FOF",
            "ICICI Pru Nifty Alpha Low Vol 30 E T F FOF",
            "ICICI Pru BSE 500 E T F FOF",
            "ICICI Pru Infrastructure Fund",
            "ICICI Pru Nifty IT Index Fund",
            "ICICI Pru Technology Fund",
            "ICICI Pru Nifty Pharma Index Fund",
            "ICICI Pru Pharma Healthcare And Diagnostics Fund",
            "ICICI Pru ELSS Tax Saver Fund",
            "ICICI Pru Banking & Financial Services Fund Ret",
            "ICICI Pru Nifty Smallcap 250 Index Fund",
            "ICICI Pru Money Market Fund",
            "ICICI Pru Nifty SDL Sep 2026 Index Fund",
            "ICICI Pru Credit Risk Fund",
            "ICICI Pru Long Term Bond Fund",
            "ICICI Pru Constant Maturity Gilt Fund",
            "ICICI Pru Floating Interest Fund",
            "ICICI Pru Regular Savings Fund",
            "ICICI Pru Income Optimizer Fund (FOF)",
            "ICICI Pru MidCap",
            "ICICI Pru Smallcap Fund",
            "ICICI Pru Global Stable Equity",
            "ICICI Pru Global Advantage Fund",
            "ICICI Pru NASDAQ 100 Index Fund",
            "ICICI Pru Strategic Metal and Energy Equity FoF",
            "ICICI Pru US Bluechip Equity Fund"
        ],
        "HSBC": [
            "HSBC Flexi Debt Fund",
            "HSBC Money Market Fund",
            "HSBC Short Duration Fund",
            "HSBC Medium to Long Duration Fund",
            "HSBC Banking and PSU Debt Fund",
            "HSBC Conservative Hybrid Fund",
            "HSBC Managed Solutions India Conservative Fund",
            "HSBC Equity Hybrid Fund",
            "HSBC Arbitrage Fund",
            "HSBC Brazil Fund",
            "HSBC Asia Pacific (Ex Japan) Dividend Yield",
            "HSBC Flexi Cap Fund",
            "HSBC Large Cap Fund",
            "HSBC Multi Cap Fund",
            "HSBC Value Fund",
            "HSBC Midcap Fund (Not in Use)",
            "HSBC Midcap Fund",
            "HSBC Small Cap Fund",
            "HSBC Short Duration Fund (Not in Use)",
            "HSBC Medium Duration Fund",
            "HSBC Low Duration Fund",
            "HSBC Managed Solutions India Growth Fund",
            "HSBC Aggressive Hybrid Fund",
            "HSBC Business Cycles Fund",
            "HSBC India Export Opportunities Fund",
            "HSBC Consumption Fund",
            "HSBC CRISIL IBX Gilt June 2027 Index Fund",
            "HSBC Dynamic Bond Fund",
            "HSBC Corporate Bond Fund",
            "HSBC Credit Risk Fund",
            "HSBC Multi Asset Allocation Fund",
            "HSBC Global Emerging Markets Fund",
            "HSBC Global Equity Climate Change FoF",
            "HSBC Equity Savings Fund",
            "HSBC Large & Mid Cap Fund",
            "HSBC Focused Fund",
            "HSBC Overnight Fund",
            "HSBC Gilt Fund",
            "HSBC Liquid Fund",
            "HSBC Infrastructure Fund",
            "HSBC ELSS Tax Saver Fund",
            "HSBC Nifty 50 Index Fund",
            "HSBC Nifty Next 50 Index Fund",
            "HSBC Infrastructure Equity Fund",
            "HSBC Ultra Short Duration Fund",
            "HSBC CRISIL IBX 50:50 Gilt Plus SDL Apr 2028 Index Fund",
            "HSBC Balanced Advantage Fund",
            "HSBC Global Consumer Opportunities Fund",
            "HSBC Tax Saver Equity Fund"
        ],
        "SBI": [
            "SBI Large & Midcap Fund",
            "SBI Focused Equity Fund",
            "SBI Savings Fund",
            "SBI Corporate Bond Fund",
            "SBI Magnum Constant Maturity Fund",
            "SBI Equity Hybrid Fund",
            "SBI Multi Asset Allocation Fund",
            "SBI Arbitrage Opp Fund",
            "SBI Innovative Opportunities Fund",
            "SBI Energy Opportunities Fund",
            "SBI Retirement Benefit Fund Conservative",
            "SBI Retirement Benefit Fund Conservative Hybrid",
            "SBI Retirement Benefit Fund Aggressive",
            "SBI Flexi Cap Fund",
            "SBI Silver E T F FoF",
            "SBI Multicap Fund",
            "SBI Dynamic Bond Fund",
            "SBI Equity Savings Fund",
            "SBI Dividend Yield Fund",
            "SBI Contra Fund",
            "SBI Magnum Gilt Fund",
            "SBI CRISIL IBX SDL Index - September 2027 Fund",
            "SBI Long Duration Fund",
            "SBI Balanced Advantage Fund",
            "SBI Magnum Childrens Benefit Fund Investment Plan",
            "SBI Short Term Debt Fund",
            "SBI Magnum Medium Duration Fund",
            "SBI M Income Fund",
            "SBI Banking and PSU Fund",
            "SBI M Global Fund",
            "SBI M Comma Fund",
            "SBI ESG Exclusionary Strategy Fund",
            "SBI Consumption Opportunities Fund",
            "SBI Equity Minimum Variance Fund",
            "SBI Automotive Opportunities Fund",
            "SBI PSU Fund",
            "SBI Magnum Childrens Benefit Fund Savings Plan",
            "SBI Retirement Benefit Fund Aggressive Hybrid",
            "SBI International Access US Equity FoF",
            "SBI Overnight Fund",
            "SBI Magnum Ultra Short Duration Fund",
            "SBI Liquid Fund",
            "SBI Gold Fund",
            "SBI Nifty50 Equal Weight Index Fund",
            "SBI Nifty 500 Index Fund",
            "SBI Nifty Midcap 150 Index Fund",
            "SBI Nifty Smallcap 250 Index Fund",
            "SBI Nifty Index Fund",
            "SBI S&P BSE Sensex Index Fund",
            "SBI Nifty Next 50 Index Fund",
            "SBI Banking & Financial Services Fund",
            "SBI Technology Opportunities Fund",
            "SBI Long Term Equity Fund",
            "SBI Infrastructure Fund",
            "SBI Healthcare Opportunities Fund",
            "SBI Blue Chip Fund",
            "SBI Magnum MidCap Fund",
            "SBI Credit Risk Fund",
            "SBI CRISIL IBX Gilt Index - June 2036 Fund",
            "SBI CRISIL IBX Gilt Index - April 2029 Fund",
            "SBI Magnum Low Duration Fund",
            "SBI Floating Rate Debt Fund",
            "SBI Conservative Hybrid Fund",
            "SBI Small Cap Fund",
            "SBI CPSE Bond Plus SDL Sep 2026 50 50 Index Fund",
            "SBI Capital Protection Oriented Fund Sr A (Plan 7)",
            "SBI Capital Protection Oriented Fund Sr A (Plan 8)"
        ],
        "Axis": [
            "Axis Dynamic Bond Fund",
            "Axis Growth Opportunities Fund",
            "Axis Focused Fund",
            "Axis Ultra Short Term Fund",
            "Axis CRISIL IBX 50: 50 Gilt Plus SDL Sep 2027 Index Fund",
            "Axis CRISIL IBX SDL May 2027 Index Fund",
            "Axis Credit Risk Fund",
            "Axis Treasury Advantage Fund",
            "Axis Retirement Savings Fund Dynamic Plan",
            "Axis Arbitrage Fund",
            "Axis ESG Integration Strategy Fund",
            "Axis Childrens Gift Fund (Lock in)",
            "Axis Midcap Fund",
            "Axis Small Cap Fund",
            "Axis Gilt Fund",
            "Axis Floater Fund",
            "Axis Midcap 50 Index Fund",
            "Axis CRISIL IBX AAA NBFC Index Jun 2027 Fund",
            "Axis CRISIL IBX 70: 30 CPSE Plus SDL April 2025 Index Fund",
            "Axis Nifty AAA Bond Plus SDL Apr 2026 50: 50 E T F FOF",
            "Axis Regular Saver Fund",
            "Axis Equity Hybrid Fund",
            "Axis Balanced Advantage Fund",
            "Axis Multi Asset Allocation Fund",
            "Axis Quant Fund",
            "Axis Innovation Fund",
            "Axis Business Cycles Fund",
            "Axis India Manufacturing Fund",
            "Axis Consumption Fund",
            "Axis Global Innovation FoF",
            "Axis Flexi cap Fund",
            "Axis All Seasons Debt Fund of Funds",
            "Axis Money Market Fund",
            "Axis Strategic Bond Fund",
            "Axis CRISIL IBX 50: 50 Gilt Plus SDL Jun 2028 Index Fund",
            "Axis Nifty G-Sec September 2032 Index Fund",
            "Axis Corporate Debt Fund",
            "Axis CRISIL IBX SDL June 2034 Debt Index Fund",
            "Axis Banking & PSU Debt",
            "Axis Silver FoF",
            "Axis Retirement Savings Fund Conservative Plan",
            "Axis Retirement Savings Fund Aggressive Plan",
            "Axis Global Equity Alpha Fund of Fund",
            "Axis Equity E T F FOF",
            "Axis Greater China Equity FoF",
            "Axis Multi Cap Fund",
            "Axis Value Fund",
            "Axis Liquid Fund",
            "Axis Nifty IT Index Fund",
            "Axis Nifty Midcap 50 Index Fund",
            "Axis Nifty 100 Index Fund",
            "Axis S&P BSE Sensex Index Fund",
            "Axis Nifty Next 50 Index Fund",
            "Axis Gold Fund",
            "Axis Nifty 500 Index Fund",
            "Axis Nifty Smallcap 50 Index Fund",
            "Axis Nifty Bank Index Fund",
            "Axis Nifty 50 Index Fund",
            "Axis ELSS Tax Saver Fund",
            "Axis Bluechip Fund",
            "Axis Equity Saver Fund",
            "Axis Overnight Fund",
            "Axis Short Term Fund",
            "Axis Nifty SDL Sep 2026 Debt Index Fund",
            "Axis Long Duration Fund",
            "Axis NASDAQ 100 FoF",
            "Axis Childrens Gift Fund",
            "Axis US Treasury Dynamic Bond E T F FoF"
        ],
        "Kotak": [
            "Kotak Savings Fund (G)",
            "Kotak Money Market Fund",
            "Kotak Low Duration Fund Std",
            "Kotak Balanced Advantage Fund",
            "Kotak Multi Asset Allocation Fund",
            "Kotak ESG Exclusionary Strategy Fund",
            "Kotak BSE PSU Index Fund",
            "Kotak Flexi Cap Fund",
            "Kotak Silver E T F FoF",
            "Kotak Bluechip Fund",
            "Kotak Emerging Equity",
            "Kotak Multicap Fund",
            "Kotak Dynamic Bond Fund",
            "Kotak India EQ Contra Fund",
            "Kotak Gilt Investment P F and Trust",
            "Kotak Gilt Investement",
            "Kotak Floating Rate Fund",
            "Kotak Smallcap Fund",
            "Kotak CRISIL IBX AAA Financial Services Index - Sep 2027 Fund",
            "Kotak Nifty SDL Apr 2027 Top 12 Equal Weight Index Fund",
            "Kotak Corporate Bond Fund",
            "Kotak Equity Hybrid",
            "Kotak Equity Arbitrage Fund",
            "Kotak Business Cycle Fund",
            "Kotak Manufacture in India Fund",
            "Kotak Pioneer Fund",
            "Kotak Nifty India Tourism Index Fund",
            "Kotak S&P BSE Housing Index Fund",
            "Kotak All Weather Debt FOF",
            "Kotak Nifty SDL Jul 2026 Index Fund",
            "Kotak Bond",
            "Kotak Credit Risk Fund",
            "Kotak Nifty AAA Bond Jun 2025 HTM Index Fund",
            "Kotak Banking & PSU Debt Fund",
            "Kotak Multi Asset Allocator FOF Dynamic",
            "Kotak Consumption Fund",
            "Kotak Special Opportunities Fund",
            "Kotak Quant Fund",
            "Kotak Global Emerging Market Fund",
            "Kotak Global Innovation Fund of Fund",
            "Kotak International REIT FOF",
            "Kotak Equity Savings Fund",
            "Kotak Equity Opportunities Fund",
            "Kotak Focused Equity Fund",
            "Kotak Healthcare Fund",
            "Kotak Banking & Financial Services Fund",
            "Kotak Nifty Financial Services Ex-Bank Index Fund",
            "Kotak Gold Fund",
            "Kotak Nifty Midcap 50 Index Fund",
            "Kotak Nifty 50 Index Fund",
            "Kotak Nifty Smallcap 50 Index Fund",
            "Kotak Nifty 200 Momentum 30 Index Fund",
            "Kotak Nifty Midcap 150 Momentum 50 Index",
            "Kotak NIFTY 100 Low Volatility 30 Index Fund",
            "Kotak Nifty Next 50 Index Fund",
            "Kotak Infra & Eco Reform",
            "Kotak Technology Fund",
            "Kotak ELSS Tax Saver Fund",
            "Kotak Bond S T P",
            "Kotak Medium Term Fund",
            "Kotak Nifty SDL Jul 2033 Index Fund",
            "Kotak Nifty SDL Apr 2032 Top 12 Equal Weight Index Fund",
            "Kotak Nifty SDL Plus AAA PSU Bond Jul 2028 60:40 Index Fund",
            "Kotak Nifty SDL Plus AAA PSU Bond Jul 2033 60:40 Index Fund",
            "Kotak Nifty G-Sec July 2033 Index Fund",
            "Kotak Long Duration Fund",
            "Kotak Debt Hybrid",
            "Kotak Overnight Fund",
            "Kotak Liquid Fund",
            "Kotak US Equity Fund Std",
            "Kotak World Gold Fund Std",
            "Kotak Nasdaq 100 FOF"
        ],

        "Tata": [
            "Tata Equity P/E Fund",
            "Tata Money Market Fund Plan A",
            "Tata Multi Asset Opportunities Fund",
            "Tata Arbitrage Fund",
            "Tata Retirement Savings Fund Moderate",
            "Tata Retirement Savings Fund Progresive",
            "Tata Retirement Savings Fund Conservative",
            "Tata Silver E T F FoF",
            "Tata Flexi Cap Fund",
            "Tata Large Cap Fund",
            "Tata Focused Equity Fund",
            "Tata Gilt Securities Plan A",
            "Tata Mid Cap Growth Fund",
            "Tata Multicap Fund",
            "Tata Ultra Short Term Fund",
            "Tata CRISIL-IBX Gilt Index - April 2026 Index Fund",
            "Tata Nifty G-Sec Dec 2029 Index Fund",
            "Tata Nifty G-Sec Dec 2026 Index Fund",
            "Tata Hybrid Equity Fund",
            "Tata Balanced Advantage Fund",
            "Tata Business Cycle Fund",
            "Tata Housing Opportunities Fund",
            "Tata Quant Fund",
            "Tata India Consumer Fund",
            "Tata Resources & Energy Fund",
            "Tata Nifty India Tourism Index Fund",
            "Tata Nifty SDL Plus AAA PSU Bond Dec 2027 60:40 Index Fund",
            "Tata Corporate Bond Fund",
            "Tata Treasury Advantage Fund",
            "Tata Ethical Fund",
            "Tata Gold E T F FoF",
            "Tata Large & Mid Cap Fund",
            "Tata Dividend Yield Fund",
            "Tata Overnight Fund",
            "Tata Liquid Fund",
            "Tata ELSS Tax Saver Fund",
            "Tata Nifty Realty Index Fund",
            "Tata Nifty Financial Services Index Fund",
            "TATA S&P BSE Sensex Index Fund",
            "Tata Nifty 50 Index Fund",
            "Tata Nifty200 Alpha 30 Index",
            "Tata Nifty Midcap 150 Momentum 50 Index Fund",
            "Tata Nifty MidSmall Healthcare Index Fund",
            "Tata Nifty Auto Index Fund",
            "Tata Nifty500 Multicap India Manufacturing 50:30:20 Index Fund",
            "Tata Nifty500 Multicap Infrastructure 50:30:20 Index Fund",
            "Tata Infrastructure Fund",
            "Tata Banking and Financial Services Fund",
            "Tata Digital India Fund",
            "Tata India Pharma & HealthCare Fund",
            "Tata Nifty India Digital E T F FoF",
            "Tata Equity Savings Fund",
            "Tata Short Term Bond Fund",
            "Tata Floating Rate Fund",
            "Tata Smallcap Fund",
            "Tata Dynamic Bond Fund Plan A",
            "Tata Medium Term Fund",
            "Tata Income Fund",
            "Tata Banking and PSU Fund",
            "Tata Nifty200 Alpha 30 Index"
        ],
        "BOI": [
            "Bank of India Overnight Fund Reg (G)",
            "Bank of India Arbitrage Fund",
            "Bank of India Smallcap Fund",
            "Bank of India Multi Asset Allocation Fund",
            "Bank of India Bluechip Fund",
            "Bank Index Fund",
            "Bank of India Liquid Fund",
            "Bank of India Flexi Cap Fund",
            "Bank of India Manufacturing and Infrastructure Fund",
            "Bank of India ST Income",
            "Bank of India Conservative Hybrid Fund",
            "Bank of India Balanced Advantage Fund",
            "Bank of India Business Cycle Fund",
            "Bank of India Multi Cap Fund",
            "Bank of India Credit Risk Fund",
            "Bank of India Ultra Short Duration Fund",
            "Bank of India ELSS Tax Saver Fund",
            "Bank of India Large & Mid Cap Equity Fund",
            "Bank of India Mid & Small Cap Equity & Debt Fund"
        ]
    }

    const handleAMCChange = (e) => {
        setSelectedCompany(e.target.value);
        setSelectedScheme(''); // Reset scheme when company changes
    };

    useEffect(() => {
        const companyData = companySchemes[selectedCompany];
        // console.log(companyData)
        if (companyData) {
            setSchemeOptions(companyData);
        } else {
            setSchemeOptions([]);
        }
    }, [selectedCompany]); // Dependency array ensures the effect runs only when selectedCompany changes

    const handleSchemeChange = (event) => {
        setSelectedScheme(event.target.value);
    };

    const handlePANChange = (event) => {
        setSelectedPAN(event.target.value);
    };

    useEffect(() => {
        getRMCode()
    })

    function getRMCode() {
        let uid = window.localStorage.getItem("uid");
        const rmRef = ref(database, `empDirectory/${uid}`)
        onValue(rmRef, (snapshot) => {
            let data = snapshot.val()
            setRMCode(`${data.empCode}`)
        })
    }
    useEffect(() => {
        const clientArray = [];
        if (rmCode) {
            const clientDirectoryRef = ref(database, "clientDirectory");
            const rmCodeRef = child(clientDirectoryRef, rmCode);

            get(rmCodeRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const clientData = snapshot.val();
                        for (const client in clientData) {
                            clientArray.push(clientData[client])
                        }
                    } else {
                        console.log("No clients found for RM Code:", rmCode);
                    }
                }).then(() => {
                    setClients(clientArray)
                })
                .catch((error) => {
                    console.error("Error fetching client data:", error);
                });
        }
    }, [rmCode, database]);

    const clientRef = ref(database, `clientDirectory/${rmCode}/${selectedPAN}/Investments/MF/${folioNumber}`)
    const submitMF = async () => {
        if (folioNumber && selectedPAN) {
            if (fundType === "Lumpsum") {
                setAUM(amount);
            }
            const clientMFInvestmentDetails = {
                investmentDate: `${investmentDate}`,
                folioNumber: `${folioNumber}`,
                AMC: `${selectedCompany}`,
                scheme: `${selectedScheme}`,
                amount: `${amount}`,
                fundType: `${fundType}`,
                company: `${company}`,
                brokerage: `${brokerage}`,
                isVisible: "false",
                aum: `${amount}`

            };

            set(clientRef, clientMFInvestmentDetails).then(() => {
                alert("Investment Added")
                navigate("/sales")
            }).catch((error) => {
                console.log(error)
            })
        } else {
            alert("Please select PAN and Folio Number")
        }
    }

    useEffect(() => {
        const foundClient = clients.find((client) => client.clientPAN === selectedPAN);
        if (foundClient) {
            setClientName(foundClient.clientName);
            setClientEmail(foundClient.clientEmail)
        }
    }, [clients, selectedPAN]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Select PAN</label>

                            <select
                                className="form-control"
                                id="clientPANSelect"
                                name="clientPAN"
                                // value={selectedPAN || (clients.length > 0 ? clients[0].clientPAN : "")}
                                onChange={handlePANChange}
                                value={selectedPAN}
                                required
                            >
                                <option value="">-- Select Client PAN --</option>
                                {clients.map((client) => (
                                    <option key={client.clientPAN} value={client.clientPAN}>
                                        {client.clientPAN}
                                    </option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">AMC</label>
                            <select
                                className="form-control"
                                value={selectedCompany} onChange={handleAMCChange}>
                                <option value="">Select Company</option>
                                {companies.map((company) => (
                                    <option key={company} value={company}>
                                        {company}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="purchasePrice">Amount</label>
                            <input
                                type="number"
                                className="form-control"
                                id="purchasePrice"
                                placeholder="Amount"
                                value={amount} // Replace "bondName" with your state variable for purchase price
                                onChange={(e) => setAmount(e.target.value)} // Update state variable for purchase price
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="purchasePrice">Brokerage</label>
                            <input
                                type="number"
                                className="form-control"
                                id="purchasePrice"
                                placeholder="Brokerage"
                                value={brokerage} // Replace "bondName" with your state variable for purchase price
                                onChange={(e) => setBrokerage(e.target.value)} // Update state variable for purchase price
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Name</label>
                            <input
                                type="texgt"
                                className="form-control"
                                id="name1"
                                placeholder="Name"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value.toUpperCase())}
                                autoCapitalize="true"
                                autoComplete="PAN"
                                readOnly
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="purchasePrice">Scheme</label>

                            <select
                                className="form-control"
                                value={selectedScheme} onChange={handleSchemeChange}>
                                <option value="">Select Scheme</option>
                                {schemeOptions.map((scheme) => (
                                    <option key={scheme} value={scheme}>
                                        {scheme}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="sellingPrice">Fund Type</label>
                            <select
                                className="form-control"
                                id="Investmenttype" name="" onChange={(e) => { setFundType(e.target.value) }}>
                                <option value="">--Select Fund Type--</option>
                                <option value="SIP">SIP</option>
                                <option value="SWP">SWP</option>
                                <option value="STP">STP</option>
                                <option value="Switch">Switch</option>
                                <option value="Lumpsum">Lumpsum</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Company</label>
                            <select
                                className="form-control"
                                id="company" name="" onChange={(e) => { setCompany(e.target.value) }}>
                                <option value="">--Select Company--</option>

                                <option value="DCS">DCS</option>
                                <option value="DFS">DFS</option>
                                <option value="DS">DS</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Email</label>
                            <input
                                type="mail"
                                className="form-control"
                                id="name1"
                                placeholder="Email"
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Folio Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name1"
                                placeholder="folio number"
                                value={folioNumber}
                                onChange={(e) => setFolioNumber(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="name1"
                                placeholder="Date"
                                value={investmentDate}
                                onChange={(e) => setInvestmentDate(e.target.value)}
                                autoComplete="date"
                            />
                        </div>


                    </div>

                </div>
                {/* Submit Button */}
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button
                            type="submit"
                            class="btn btn-primary btn-rounded btn-fw"
                            onClick={submitMF}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AddMF

