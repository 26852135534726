import React, { useEffect, useState, useTransition } from "react";
import { Link } from "react-router-dom";
import { app } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import Footer from "../components/Footer";
import { getDatabase, ref, onValue, get, child } from "firebase/database";
import CSS from "../dashboard.css"
function Dashboard() {
  const database = getDatabase(app);
  const navigate = useNavigate();
  const auth = getAuth(app);
  const [temp, setTemp] = useState("")
  const [aqi, setAqi] = useState("")
  const [empNumber, setEmpNumber] = useState(0);
  let [userName, setUserName] = useState("");
  let [date, setDate] = useState();
  let newDate = new Date()

  //setting current date on dashboard
  useEffect(() => {
    setDate(`${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`)
  })



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        window.localStorage.setItem("uid", `${user.uid}`);

        if (user.uid) {
          const empRef = ref(database, `empDirectory/${user.uid}`);
          getUserInfo(empRef);
        }
      } else {
        navigate("/");
      }
    });
    function getUserInfo(empRef) {
      onValue(empRef, (snapshot) => {
        const data = snapshot.val()
        setUserName(data.empName)
        if (data.empRole === 'admin') {
          window.localStorage.setItem("isAdmin", true)
        } else {
          window.localStorage.setItem("isAdmin", false)
        }
        if (data.empRole === "hr") {
          window.localStorage.setItem("isAdmin", "hr")
        }
      })
    }
    return () => unsubscribe();
  }, [auth, database, navigate]); // Empty dependency array ensures that this effect runs only once after initial render

  useEffect(() => {
    fetch("http://api.weatherapi.com/v1/current.json?key=3fe41b3996f942d79bb70056242309&q=25.3611,81.4032&aqi=yes")
      .then((response) => {
        if (!response.ok) {
          // Handle error: e.g., console.error or display a message to the user
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTemp(data.current.temp_c)
        setAqi(data.current.air_quality.pm2_5)

      })
      .catch((error) => {
        console.error("Error fetching weather data:", error);
      });
  }, []);

  useEffect(() => {
    const dbref = ref(database, "empDirectory/")
    onValue(dbref, ((snapshot) => {
      const data = snapshot.val();
      let i = 0
      for (const element in data) {
        i++
      }
      setEmpNumber(i)

    }))
  })

  function getRandomHSLColor() {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 7 + 50);
    const lightness = Math.floor(Math.random() * 8 + 89);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  function downloadbtn() {
    let btn = document.querySelectorAll(".download-btn")
    btn.forEach(element => {
      const bg = getRandomHSLColor();
      console.log(bg)
      element.style.backgroundColor = `${bg}`
    });
  }
  useEffect(() => {
    downloadbtn();
  },[])

  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <SideBar />
          {/* Main Page Start Here  */}
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row">
                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                      <h3 className="font-weight-bold">Welcome {userName}</h3>
                      <h6 className="font-weight-normal mb-0">
                        All systems are running smoothly!
                      </h6>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card tale-bg">
                    <div className="card-people mt-auto">
                      <img
                        src="assets/images/dashboard/people.svg"
                        alt="people"
                      />
                      <div className="weather-info">
                        <div className="d-flex">
                          <div>
                            <h2
                              id="weather"
                              className="mb-0 font-weight-normal"
                            >
                              {parseFloat(temp).toFixed(1)}°C
                            </h2>
                          </div>
                          <div className="ml-2">
                            <h4 className="location font-weight-normal">
                              Kaushambi
                            </h4>
                            <h6 className="font-weight-normal">India</h6>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div>
                            <h2
                              id="aqi"
                              className="mb-0 font-weight-normal"
                            >
                              {parseFloat(aqi).toFixed(2)} pm2.5
                            </h2>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin transparent">
                  <div className="row">
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-tale">
                        <div className="card-body">
                          <p className="mb-4">Total Employees</p>
                          <p className="fs-30 mb-2">{empNumber}</p>
                          <p style={{ fontWeight: 600 }}>{date}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-dark-blue">
                        <div className="card-body">
                          <p className="mb-4">Total Bookings</p>
                          <p className="fs-30 mb-2">61344</p>
                          <p>22.00% (30 days)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                      <div className="card card-light-blue">
                        <div className="card-body">
                          <p className="mb-4">Number of Meetings</p>
                          <p className="fs-30 mb-2">34040</p>
                          <p>2.00% (30 days)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 stretch-card transparent">
                      <div className="card card-light-danger">
                        <div className="card-body">
                          <p className="mb-4">Number of Clients</p>
                          <p className="fs-30 mb-2">47033</p>
                          <p>0.22% (30 days)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin transparent">
                  <div className="row">
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <a className="card card-tale" href="https://firebasestorage.googleapis.com/v0/b/dimension-bf7ed.appspot.com/o/HR%20Manual.pdf?alt=media&token=d6d4eef4-6355-41d4-a92a-c42edfcfe83f" target="blank">
                        <button className="download-btn">
                          <div src="./images/download.png" alt="" />
                          <p style={{ fontWeight: 500 }}>HR Polocies</p>
                        </button>
                      </a>
                    </div>
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <a target="blank" href="https://firebasestorage.googleapis.com/v0/b/dimension-bf7ed.appspot.com/o/Advance%20Salary%20Form.pdf?alt=media&token=870dfe35-1046-472d-95b6-2909f91a8a5e" className="card card-dark-blue">
                        <button className="download-btn">
                          <div src="./images/download-square-svgrepo-com.svg" alt="" />

                          <p style={{ fontWeight: 500 }}>Salary Advance Form</p>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                      <a target="blank" href="https://firebasestorage.googleapis.com/v0/b/dimension-bf7ed.appspot.com/o/Diwali%20Gift%20Form.pdf?alt=media&token=88abf92f-13df-49df-b1a2-b5a7a593ced6" className="card card-light-blue">
                        <button className="download-btn">
                          <div src="./images/download.png" alt="" />
                          <p style={{ fontWeight: 500 }}>Diwali Gift Form</p>
                        </button>
                      </a>
                    </div>
                    <div className="col-md-6 stretch-card transparent">
                      <a target="blank" href="https://firebasestorage.googleapis.com/v0/b/dimension-bf7ed.appspot.com/o/Exit%20Interview%20Form.pdf?alt=media&token=9526f844-0715-47b1-b275-c66e495f59fe" className="card card-light-danger">
                        <button className="download-btn">
                          <div src="./images/download.png" alt="" />

                          <p style={{ fontWeight: 500 }}>Exit Interview Form</p>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title">Order Details</p>
                      <p className="font-weight-500">
                        The total number of sessions within the date range. It
                        is the period time a user is actively engaged with your
                        website, page or app, etc
                      </p>
                      <div className="d-flex flex-wrap mb-5">
                        <div className="mr-5 mt-3">
                          <p className="text-muted">Order value</p>
                          <h3 className="text-primary fs-30 font-weight-medium">
                            12.3k
                          </h3>
                        </div>
                        <div className="mr-5 mt-3">
                          <p className="text-muted">Orders</p>
                          <h3 className="text-primary fs-30 font-weight-medium">
                            14k
                          </h3>
                        </div>
                        <div className="mr-5 mt-3">
                          <p className="text-muted">Users</p>
                          <h3 className="text-primary fs-30 font-weight-medium">
                            71.56%
                          </h3>
                        </div>
                        <div className="mt-3">
                          <p className="text-muted">Downloads</p>
                          <h3 className="text-primary fs-30 font-weight-medium">
                            34040
                          </h3>
                        </div>
                      </div>
                      <canvas id="order-chart" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <p className="card-title">Sales Report</p>
                        <Link to="#" className="text-info">
                          View all
                        </Link>
                      </div>
                      <p className="font-weight-500">
                        The total number of sessions within the date range. It
                        is the period time a user is actively engaged with your
                        website, page or app, etc
                      </p>
                      <div
                        id="sales-legend"
                        className="chartjs-legend mt-4 mb-2"
                      />
                      <canvas id="sales-chart" />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                  <div class="card position-relative">
                    <div class="card-body">
                      <div id="detailedReports" class="carousel slide detailed-report-carousel position-static pt-2" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <div class="row">
                              <div class="col-md-12 col-xl-3 d-flex flex-column justify-content-start">
                                <div class="ml-xl-4 mt-3">
                                <p class="card-title">Detailed Reports</p>
                                  <h1 class="text-primary">$34040</h1>
                                  <h3 class="font-weight-500 mb-xl-4 text-primary">North America</h3>
                                  <p class="mb-2 mb-xl-0">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
                                </div>  
                                </div>
                              <div class="col-md-12 col-xl-9">
                                <div class="row">
                                  <div class="col-md-6 border-right">
                                    <div class="table-responsive mb-3 mb-md-0 mt-3">
                                      <table class="table table-borderless report-table">
                                        <tr>
                                          <td class="text-muted">Illinois</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-primary" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">713</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Washington</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-warning" role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">583</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Mississippi</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">924</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">California</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-info" role="progressbar" style="width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">664</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Maryland</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-primary" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">560</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Alaska</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-danger" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">793</h5></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="col-md-6 mt-3">
                                    <canvas id="north-america-chart"></canvas>
                                    <div id="north-america-legend"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="carousel-item">
                            <div class="row">
                              <div class="col-md-12 col-xl-3 d-flex flex-column justify-content-start">
                                <div class="ml-xl-4 mt-3">
                                <p class="card-title">Detailed Reports</p>
                                  <h1 class="text-primary">$34040</h1>
                                  <h3 class="font-weight-500 mb-xl-4 text-primary">North America</h3>
                                  <p class="mb-2 mb-xl-0">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
                                </div>  
                                </div>
                              <div class="col-md-12 col-xl-9">
                                <div class="row">
                                  <div class="col-md-6 border-right">
                                    <div class="table-responsive mb-3 mb-md-0 mt-3">
                                      <table class="table table-borderless report-table">
                                        <tr>
                                          <td class="text-muted">Illinois</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-primary" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">713</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Washington</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-warning" role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">583</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Mississippi</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">924</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">California</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-info" role="progressbar" style="width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">664</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Maryland</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-primary" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">560</h5></td>
                                        </tr>
                                        <tr>
                                          <td class="text-muted">Alaska</td>
                                          <td class="w-100 px-0">
                                            <div class="progress progress-md mx-4">
                                              <div class="progress-bar bg-danger" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                          </td>
                                          <td><h5 class="font-weight-bold mb-0">793</h5></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="col-md-6 mt-3">
                                    <canvas id="south-america-chart"></canvas>
                                    <div id="south-america-legend"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a class="carousel-control-prev" href="#detailedReports" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#detailedReports" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-md-7 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title mb-0">Employees</p>
                      <div className="table-responsive">
                        <table className="table table-striped table-borderless">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Emp Code</th>
                              <th>Designation</th>
                              <th>DOJ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Search Engine Marketing</td>
                              <td className="font-weight-bold">$362</td>
                              <td>21 Sep 2018</td>
                              <td className="font-weight-medium">
                                <div className="badge badge-success">
                                  Completed
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Search Engine Optimization</td>
                              <td className="font-weight-bold">$116</td>
                              <td>13 Jun 2018</td>
                              <td className="font-weight-medium">
                                <div className="badge badge-success">
                                  Completed
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Display Advertising</td>
                              <td className="font-weight-bold">$551</td>
                              <td>28 Sep 2018</td>
                              <td className="font-weight-medium">
                                <div className="badge badge-warning">
                                  Pending
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Pay Per Click Advertising</td>
                              <td className="font-weight-bold">$523</td>
                              <td>30 Jun 2018</td>
                              <td className="font-weight-medium">
                                <div className="badge badge-warning">
                                  Pending
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>E-Mail Marketing</td>
                              <td className="font-weight-bold">$781</td>
                              <td>01 Nov 2018</td>
                              <td className="font-weight-medium">
                                <div className="badge badge-danger">
                                  Cancelled
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Referral Marketing</td>
                              <td className="font-weight-bold">$283</td>
                              <td>20 Mar 2018</td>
                              <td className="font-weight-medium">
                                <div className="badge badge-warning">
                                  Pending
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Social media marketing</td>
                              <td className="font-weight-bold">$897</td>
                              <td>26 Oct 2018</td>
                              <td className="font-weight-medium">
                                <div className="badge badge-success">
                                  Completed
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="col-md-5 grid-margin stretch-card">
    							<div class="card">
    								<div class="card-body">
    									<h4 class="card-title">To Do Lists</h4>
    									<div class="list-wrapper pt-2">
    										<ul class="d-flex flex-column-reverse todo-list todo-list-custom">
    											<li>
    												<div class="form-check form-check-flat">
    													<label class="form-check-label">
    														<input class="checkbox" type="checkbox">
    														Meeting with Urban Team
    													</label>
    												</div>
    												<i class="remove ti-close"></i>
    											</li>
    											<li class="completed">
    												<div class="form-check form-check-flat">
    													<label class="form-check-label">
    														<input class="checkbox" type="checkbox" checked>
    														Duplicate a project for new customer
    													</label>
    												</div>
    												<i class="remove ti-close"></i>
    											</li>
    											<li>
    												<div class="form-check form-check-flat">
    													<label class="form-check-label">
    														<input class="checkbox" type="checkbox">
    														Project meeting with CEO
    													</label>
    												</div>
    												<i class="remove ti-close"></i>
    											</li>
    											<li class="completed">
    												<div class="form-check form-check-flat">
    													<label class="form-check-label">
    														<input class="checkbox" type="checkbox" checked>
    														Follow up of team zilla
    													</label>
    												</div>
    												<i class="remove ti-close"></i>
    											</li>
    											<li>
    												<div class="form-check form-check-flat">
    													<label class="form-check-label">
    														<input class="checkbox" type="checkbox">
    														Level up for Antony
    													</label>
    												</div>
    												<i class="remove ti-close"></i>
    											</li>
    										</ul>
                      </div>
                      <div class="add-items d-flex mb-0 mt-2">
    										<input type="text" class="form-control todo-list-input"  placeholder="Add new task">
    										<button class="add btn btn-icon text-primary todo-list-add-btn bg-transparent"><i class="icon-circle-plus"></i></button>
    									</div>
    								</div>
    							</div>
                </div> 
              </div> */}
              {/* <div className="row">
                <div className="col-md-4 stretch-card grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title mb-0">Projects</p>
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className="pl-0  pb-2 border-bottom">
                                Places
                              </th>
                              <th className="border-bottom pb-2">Orders</th>
                              <th className="border-bottom pb-2">Users</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="pl-0">Kentucky</td>
                              <td>
                                <p className="mb-0">
                                  <span className="font-weight-bold mr-2">
                                    65
                                  </span>
                                  (2.15%)
                                </p>
                              </td>
                              <td className="text-muted">65</td>
                            </tr>
                            <tr>
                              <td className="pl-0">Ohio</td>
                              <td>
                                <p className="mb-0">
                                  <span className="font-weight-bold mr-2">
                                    54
                                  </span>
                                  (3.25%)
                                </p>
                              </td>
                              <td className="text-muted">51</td>
                            </tr>
                            <tr>
                              <td className="pl-0">Nevada</td>
                              <td>
                                <p className="mb-0">
                                  <span className="font-weight-bold mr-2">
                                    22
                                  </span>
                                  (2.22%)
                                </p>
                              </td>
                              <td className="text-muted">32</td>
                            </tr>
                            <tr>
                              <td className="pl-0">North Carolina</td>
                              <td>
                                <p className="mb-0">
                                  <span className="font-weight-bold mr-2">
                                    46
                                  </span>
                                  (3.27%)
                                </p>
                              </td>
                              <td className="text-muted">15</td>
                            </tr>
                            <tr>
                              <td className="pl-0">Montana</td>
                              <td>
                                <p className="mb-0">
                                  <span className="font-weight-bold mr-2">
                                    17
                                  </span>
                                  (1.25%)
                                </p>
                              </td>
                              <td className="text-muted">25</td>
                            </tr>
                            <tr>
                              <td className="pl-0">Nevada</td>
                              <td>
                                <p className="mb-0">
                                  <span className="font-weight-bold mr-2">
                                    52
                                  </span>
                                  (3.11%)
                                </p>
                              </td>
                              <td className="text-muted">71</td>
                            </tr>
                            <tr>
                              <td className="pl-0 pb-0">Louisiana</td>
                              <td className="pb-0">
                                <p className="mb-0">
                                  <span className="font-weight-bold mr-2">
                                    25
                                  </span>
                                  (1.32%)
                                </p>
                              </td>
                              <td className="pb-0">14</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 stretch-card grid-margin">
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <p className="card-title">Charts</p>
                          <div className="charts-data">
                            <div className="mt-3">
                              <p className="mb-0">Data 1</p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="progress progress-md flex-grow-1 mr-4">
                                  <div
                                    className="progress-bar bg-inf0"
                                    role="progressbar"
                                    style={{ width: "95%" }}
                                    aria-valuenow={95}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                                <p className="mb-0">5k</p>
                              </div>
                            </div>
                            <div className="mt-3">
                              <p className="mb-0">Data 2</p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="progress progress-md flex-grow-1 mr-4">
                                  <div
                                    className="progress-bar bg-info"
                                    role="progressbar"
                                    style={{ width: "35%" }}
                                    aria-valuenow={35}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                                <p className="mb-0">1k</p>
                              </div>
                            </div>
                            <div className="mt-3">
                              <p className="mb-0">Data 3</p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="progress progress-md flex-grow-1 mr-4">
                                  <div
                                    className="progress-bar bg-info"
                                    role="progressbar"
                                    style={{ width: "48%" }}
                                    aria-valuenow={48}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                                <p className="mb-0">992</p>
                              </div>
                            </div>
                            <div className="mt-3">
                              <p className="mb-0">Data 4</p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="progress progress-md flex-grow-1 mr-4">
                                  <div
                                    className="progress-bar bg-info"
                                    role="progressbar"
                                    style={{ width: "25%" }}
                                    aria-valuenow={25}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                                <p className="mb-0">687</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 stretch-card grid-margin grid-margin-md-0">
                      <div className="card data-icon-card-primary">
                        <div className="card-body">
                          <p className="card-title text-white">
                            Number of Meetings
                          </p>
                          <div className="row">
                            <div className="col-8 text-white">
                              <h3>34040</h3>
                              <p className="text-white font-weight-500 mb-0">
                                The total number of sessions within the date
                                range.It is calculated as the sum .{" "}
                              </p>
                            </div>
                            <div className="col-4 background-icon"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 stretch-card grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title">Notifications</p>
                      <ul className="icon-data-list">
                        <li>
                          <div className="d-flex">
                            <img
                              src="assets/images/faces/face1.jpg"
                              alt="user"
                            />
                            <div>
                              <p className="text-info mb-1">Isabella Becker</p>
                              <p className="mb-0">
                                Sales dashboard have been created
                              </p>
                              <small>9:30 am</small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <img
                              src="assets/images/faces/face2.jpg"
                              alt="user"
                            />
                            <div>
                              <p className="text-info mb-1">Adam Warren</p>
                              <p className="mb-0">
                                You have done a great job #TW111
                              </p>
                              <small>10:30 am</small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <img
                              src="assets/images/faces/face3.jpg"
                              alt="user"
                            />
                            <div>
                              <p className="text-info mb-1">Leonard Thornton</p>
                              <p className="mb-0">
                                Sales dashboard have been created
                              </p>
                              <small>11:30 am</small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <img
                              src="assets/images/faces/face4.jpg"
                              alt="user"
                            />
                            <div>
                              <p className="text-info mb-1">George Morrison</p>
                              <p className="mb-0">
                                Sales dashboard have been created
                              </p>
                              <small>8:50 am</small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <img
                              src="assets/images/faces/face5.jpg"
                              alt="user"
                            />
                            <div>
                              <p className="text-info mb-1">Ryan Cortez</p>
                              <p className="mb-0">
                                Herbs are fun and easy to grow.
                              </p>
                              <small>9:00 am</small>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title">Advanced Table</p>
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table
                              id="example"
                              className="display expandable-table"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Quote#</th>
                                  <th>Product</th>
                                  <th>Business type</th>
                                  <th>Policy holder</th>
                                  <th>Premium</th>
                                  <th>Status</th>
                                  <th>Updated at</th>
                                  <th />
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* content-wrapper ends */}
            {/* Footer start */}
            <Footer />
            {/* footer end */}
          </div>
          {/* main-panel ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    </>
  );
}

export default Dashboard;