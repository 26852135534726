import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "./firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Encriptor from "encriptorjs";
import { getDatabase, onValue, ref } from "firebase/database";
function Login() {

  const database = getDatabase(app);
  const forgotButton = {
    border: "none",
  };
  const auth = getAuth(app);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const handleSignIn = () => {
    if (!email || !password) {
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const secret = ref(database, "secretKey/");
        onValue(secret, (snapshot) => {
          const key = snapshot.val();
          const passValue = `${password}`;
          const encryptedText = Encriptor.encrypt(passValue, key);
          window.localStorage.setItem("email", `${email}`);
          window.localStorage.setItem("key", `${encryptedText}`);
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 100);
      })
      .catch((error) => {
        console.log(error);
        let errorText = document.getElementById("signin-text");
        errorText.innerText = "Wrong Email or password";
        errorText.style.color = "red";
        errorText.style.fontWeight = "bold";
        setTimeout(() => {
          errorText.innerText = "Sign in to continue.";
          errorText.style.color = "black";
        }, 4000);
      });
  };

  const handleForgotPassword = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => { })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });
  };

  const handleButtonClick = () => {
    navigate("/leave-request");
  };

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src="assets/images/dimesnion-logo-s.png" alt="logo" />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 id="signin-text" className="font-weight-light">
                    Sign in to continue.
                  </h6>
                  <form onSubmit={handleSignIn} className="pt-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Email"
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        onChange={handlePasswordChange}
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={handleSignIn}
                      >
                        SIGN IN
                      </button>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      {/* <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          Keep me signed in
                        </label>
                      </div> */}
                      <a
                        href="/"
                        className="auth-link text-black forgot-button"
                        onClick={handleForgotPassword}
                        style={forgotButton}
                      >
                        Forgot password?
                      </a>
                    </div>
                    <div className="mb-2">
                      <button
                        type="button"
                        className="btn btn-block btn-facebook auth-form-btn"
                        onClick={handleButtonClick}
                      >
                        {/* <i className="ti-facebook mr-2" /> */}
                        Apply for Leave
                      </button>
                    </div>
                    {/* <div className="text-center mt-4 font-weight-light">
                      Don't have an account?{" "}
                      <a href="register.html" className="text-primary">
                        Create
                      </a>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    </>
  );
}

export default Login;
