import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { app } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, off } from "firebase/database";
import EmpTable from "../components/EmpployeeTable";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import Footer from "../components/Footer";

function Employee() {
  const navigate = useNavigate();
  const auth = getAuth(app);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);
  let [employees, setEmployees] = useState([]);
  useEffect(() => {
    const database = getDatabase(app); // Initialize database inside useEffect
    const empRef = ref(database, "empDirectory/"); // Create reference to the database location

    const handleValueChange = (snapshot) => {
      const data = snapshot.val();
      const employeeArray = [];
      for (const empCode in data) {
        employeeArray.push(data[empCode]); // Add employee object to the array
      }
      setEmployees(employeeArray); // Update state with new data
    };

    // Set up the real-time listener
    onValue(empRef, handleValueChange);

    // Clean up the listener on unmount
    return () => {
      off(empRef, "value", handleValueChange);
    };
  }, []);
  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <SideBar />
          {/* Main Page Start Here  */}
          <div className="main-panel">
            <div className="content-wrapper">
              <Link to="/add-employess">
                <button
                  type="button"
                  class="btn btn-primary btn-rounded btn-fw"
                >
                  Add Employees
                </button>
              </Link>

              <div className="row mt-3">
                <div className="col-md grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title mb-0">Employees</p>
                      <div className="table-responsive">
                        <table className="table table-striped table-borderless">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Emp Code</th>
                              <th>Designation</th>
                              <th>DOJ</th>
                            </tr>
                          </thead>
                          {/* component for table */}
                          <EmpTable employees={employees} />
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            {/* content-wrapper ends */}
            {/* Footer start */}
            <Footer />
            {/* footer end */}
          </div>
          {/* main-panel ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    </>
  );
}

export default Employee;