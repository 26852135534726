import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, onValue, child, set, increment, update } from "firebase/database";
import { app } from "./firebase";

function ClientDetails() {
    const navigate = useNavigate();
    const [rm, setRM] = useState("");
    const [panNumber, setPAN] = useState("")
    const database = getDatabase(app)
    const [clientDetails, setClientDetails] = useState("");
    const [clientName, setclientName] = useState();
    const [mfInvestments, setMfInvestments] = useState();
    const [bondInvestments, setBondInvestment] = useState();
    const [fdInvestments, setFDInvestment] = useState();
    const isAdmin = window.localStorage.getItem("isAdmin");

    useEffect(() => {
        setclientName(window.localStorage.getItem("Name"))
        setRM(window.localStorage.getItem("rm"))
        setPAN(window.localStorage.getItem("PAN"))
    }, [])

    useEffect(() => {
        const dbRef = ref(database, `clientDirectory/${rm}`);
        const unsubscribe = onValue(dbRef, (snapshot) => {
            const data = snapshot.val();

            if (data) {
                const clientData = data[panNumber]; // Directly access the client data using panNumber

                // mf investment data ***************************************************************
                if (clientData) {
                    try {
                        if (clientData.Investments && clientData.Investments.MF) {
                            setMfInvestments(clientData.Investments.MF);
                        } else {
                            // No MF data for this client
                            setMfInvestments(null);
                            let mf_data = document.getElementById("mf-data");
                            mf_data.style.color = "red";
                            mf_data.innerText = "No Mutual Fund data to show";
                        }
                    } catch (error) {
                        console.error("Error accessing MF data:", error);
                        let mf_data = document.getElementById("mf-data");
                        mf_data.style.color = "red";
                        mf_data.innerText = "Error: Unable to fetch Mutual Fund data";
                    }
                } else {
                    setMfInvestments(null); // No client data found
                }

                // bonds investment data ***************************************************************
                if (clientData) {
                    try {
                        if (clientData.Investments && clientData.Investments.Bonds) {
                            setBondInvestment(clientData.Investments.Bonds);
                        } else {
                            // No bond data for this client
                            setBondInvestment(null);
                            let bond_data = document.getElementById("bond-data");
                            bond_data.style.color = "red";
                            bond_data.innerText = "No Bond data to show";
                        }
                    } catch (error) {
                        console.error("Error accessing Bond data:", error);
                        let bond_data = document.getElementById("bond-data");
                        bond_data.style.color = "red";
                        bond_data.innerText = "Error: Unable to fetch Bond data";
                    }
                } else {
                    setBondInvestment(null); // No client data found
                }
                // fd investment data ***************************************************************
                if (data) {
                    const clientData = data[panNumber];

                    if (clientData) {
                        try {
                            if (clientData.Investments && clientData.Investments.FD) {
                                setFDInvestment(clientData.Investments.FD);
                            } else {
                                // No FD data for this client
                                setFDInvestment(null);
                                let fd_data = document.getElementById("fd-data");
                                fd_data.style.color = "red";
                                fd_data.innerText = "No FD data to show";
                            }
                        } catch (error) {
                            console.error("Error accessing FD data:", error);
                            let fd_data = document.getElementById("fd-data");
                            fd_data.style.color = "red";
                            fd_data.innerText = "Error: Unable to fetch FD data";
                        }
                    } else {
                        setFDInvestment(null); // Client doesn't exist
                    }
                } else {
                    setFDInvestment(null); // No data for the rm
                }

            } else {
                setMfInvestments(null); // No data for the rm
                setBondInvestment(null)
                setFDInvestment(null)
            }
        });

        return () => unsubscribe();
    }, [rm, database, panNumber]);


    function calculateNetAmount(
        amount, startDate,
        folioNumber, fundType, aum) {
        const clientRef = ref(database, `clientDirectory/${rm}/${panNumber}/Investments/MF/${folioNumber}/`)
        if (fundType === "SIP") {
            const currentDate = new Date();
            const startDateObj = new Date(startDate);
            const timeDiff = currentDate - startDateObj;
            const daysElapsed = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
            const monthsElapsed = Math.floor(daysElapsed / 30.4) + 1;
            const netSIPAmount = parseInt(amount * monthsElapsed);
            const detail = {
                aum: `${netSIPAmount}`
            }
            if (parseInt(aum) !== netSIPAmount) {
                update(clientRef, detail).then(() => {
                    console.log("SIP AUM Updated");
                }).catch((error) => {
                    console.log(error)
                })
            }
            return netSIPAmount;
        } else if (fundType === "Lumpsum") {
            // for lumpsum amount
            return aum
        }else if(fundType === "Switch"){
            return aum
        }
    }

    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <SideBar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <h3>{clientName}
                            </h3>
                            <div className="row mt-3">
                                <div className="col-md grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p id="mf-data" className="card-title mb-0">Mutual Fund</p>
                                            <div className="table-responsive">
                                                <table className="table table-striped table-borderless">
                                                    <thead>
                                                        <tr>
                                                            <th>Folio number</th>
                                                            <th>Scheme</th>
                                                            <th>Date</th>
                                                            <th>Type</th>
                                                            <th>Company</th>
                                                            <th>Amount</th>
                                                            <th>AUM</th>
                                                            {isAdmin === "true" ? (
                                                                <th>Brokerage</th>
                                                            ) : null}
                                                            <th>Approved</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {mfInvestments && Object.keys(mfInvestments).map((key) => {
                                                            const investment = mfInvestments[key];
                                                            if ((isAdmin === "true") || (isAdmin === "false")) {
                                                                return (
                                                                    <tr key={key}>
                                                                        {isAdmin === "true" ? (
                                                                            <td>
                                                                                <a className="redirect-page" onClick={() => { navigate('/mf-edit', { state: { investment, panNumber, rm } }) }}>                                                                        {investment.bondISIN}
                                                                                    {investment.folioNumber}
                                                                                </a>
                                                                            </td>
                                                                        ) : (
                                                                            <td>{investment.folioNumber || investment.fdr}</td>
                                                                        )}
                                                                        <td>{investment.scheme}</td>
                                                                        <td>{investment.investmentDate}</td>
                                                                        <td>{investment.fundType}</td>
                                                                        <td>{investment.company || ""}</td>
                                                                        <td className="investment">{investment.amount}</td>
                                                                        {(
                                                                            <td style={{ color: "#1e1e1e", fontWeight: 700 }} >{calculateNetAmount(
                                                                                investment.amount, investment.investmentDate,
                                                                                investment.folioNumber, investment.fundType, investment.aum)}</td>
                                                                        )}
                                                                        {isAdmin === "true" ? (
                                                                            <td>{investment.brokerage}</td>
                                                                        ) : null}

                                                                        {(investment.isVisible === "true") ? (<td style={{ color: "#78BC61", fontWeight: 700 }}>Yes</td>)
                                                                            : (<td style={{ color: "#DB5A42 ", fontWeight: 500 }}> No</td>)}
                                                                    </tr>
                                                                );
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col-md grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p id="bond-data" className="card-title mb-0">Bonds</p>
                                            <div className="table-responsive">
                                                <table className="table table-striped table-borderless">
                                                    <thead>
                                                        <tr>
                                                            <th>ISIN</th>
                                                            <th>Security Name</th>
                                                            <th>Amount</th>
                                                            <th>Company</th>
                                                            <th>Purchase</th>
                                                            <th>Sell</th>
                                                            {isAdmin === "true" ? (<th>Brokerage</th>) : null}
                                                            {isAdmin === "true" ? (<th>Profit</th>) : null}
                                                            {isAdmin === "true" ? (<th>Total Profit</th>) : null}
                                                            <th>Aprroved</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bondInvestments && Object.keys(bondInvestments).map((key) => {
                                                            const investment = bondInvestments[key];
                                                            if ((isAdmin === "true") || (isAdmin === "false")) {
                                                                return (
                                                                    <tr key={key}>
                                                                        {isAdmin === "true" ? (
                                                                            <td>
                                                                                <a className="redirect-page" onClick={() => { navigate('/investment-edit', { state: { investment, panNumber, rm } }) }}>
                                                                                    {investment.bondISIN}
                                                                                </a>
                                                                            </td>
                                                                        ) : (
                                                                            <td>{investment.bondISIN}</td>
                                                                        )}
                                                                        <td>{investment.bondName}</td>
                                                                        <td>{investment.bondAmount}</td>
                                                                        <td>{investment.company}</td>
                                                                        <td>{investment.purchasePrice}</td>
                                                                        <td>{investment.sellingPrice}</td>
                                                                        {isAdmin === "true" ? (<td>{investment.brokerage}</td>) : null}
                                                                        {isAdmin === "true" ? (<td>{investment.profit}</td>) : null}
                                                                        {isAdmin === "true" ? (<td style={{ color: "#1e1e1e", fontWeight: 700 }}>{investment.totalProfit}</td>) : null}
                                                                        {(investment.isVisible === "true") ? (<td style={{ color: "#78BC61", fontWeight: 700 }}>Yes</td>)
                                                                            : (<td style={{ color: "#DB5A42 ", fontWeight: 500 }}> No</td>)}
                                                                    </tr>
                                                                );
                                                            }

                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col-md grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p id="fd-data" className="card-title mb-0">Fixed Deposits</p>
                                            <div className="table-responsive">
                                                <table className="table table-striped table-borderless">
                                                    <thead>
                                                        <tr>
                                                            <th>FDR Code</th>
                                                            <th>FD Name</th>
                                                            <th>FD Rate</th>
                                                            <th>FD Type</th>
                                                            <th>Start Date</th>
                                                            <th>Duration (Months)</th>
                                                            <th>Maturity Date</th>
                                                            <th>FD Amount</th>
                                                            <th>Approved</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fdInvestments && Object.keys(fdInvestments).map((key) => {
                                                            const investment = fdInvestments[key];
                                                            if ((isAdmin === "true") || (investment.isVisible === "true" && isAdmin === "false")) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{investment.fdr}</td>
                                                                        <td>{investment.fdName}</td>
                                                                        <td>{investment.fdRate}</td>
                                                                        <td>{investment.fdType}</td>
                                                                        <td>{investment.fdStartDate}</td>
                                                                        <td>{investment.fdDuration}</td>
                                                                        <td>{investment.maturityDate}</td>
                                                                        <td style={{ color: "#1e1e1e ", fontWeight: 700 }}>{investment.fdAmount}</td>
                                                                        {(investment.isVisible === "true") ? (<td style={{ color: "#78BC61", fontWeight: 700 }}>Yes</td>)
                                                                            : (<td style={{ color: "#DB5A42 ", fontWeight: 500 }}> No</td>)}
                                                                    </tr>
                                                                );
                                                            }

                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Footer />
                        {/* footer end */}
                    </div>
                </div>
            </div >
        </>
    )
}
export default ClientDetails;