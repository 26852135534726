import { useEffect, useState } from "react"
import { app } from "../pages/firebase"
import { useNavigate } from "react-router-dom"
import { getDatabase, ref, onValue, child, get, set } from "firebase/database"

function AddBonds() {
    const navigate = useNavigate()
    const database = getDatabase(app)
    const [selectedPAN, setSelectedPAN] = useState("");
    let [clients, setClients] = useState([]);
    let [rmCode, setRMCode] = useState("")
    const [clientName, setClientName] = useState("")
    const [clientEmail, setClientEmail] = useState("")
    const [bondISIN, setBondsISIN] = useState("")
    const [bondName, setBondName] = useState("")
    const [bondCallDate, setBondCallDate] = useState("");
    const [bondMaturityDate, setBondMaturityDate] = useState("")
    const [settlementDate, setSettlementDate] = useState("")
    const [bondAmount, setBondAmount] = useState("")
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [brokerage, setBrokerage] = useState(0);
    const [profit, setProfit] = useState();
    // const [faceValue, setFaceValue] = useState('');
    const [bondQuantity, setBondQuantity] = useState("")
    const [company, setCompany] = useState('');
    const [isAdmin, setAdminRole] = useState(false)
    const [totalProfit, setTotalProfit] = useState();
    const handlePANChange = (event) => {
        setSelectedPAN(event.target.value);
    };

    useEffect(() => {
        let val = window.localStorage.getItem("isAdmin")
        setAdminRole(val);
    }, [])

    useEffect(() => {
        getRMCode()
    })

    function getRMCode() {
        let uid = window.localStorage.getItem("uid");
        const rmRef = ref(database, `empDirectory/${uid}`)
        onValue(rmRef, (snapshot) => {
            let data = snapshot.val()
            setRMCode(`${data.empCode}`)
        })
    }

    useEffect(() => {
        const clientArray = [];
        if (rmCode) {
            const clientDirectoryRef = ref(database, "clientDirectory");
            const rmCodeRef = child(clientDirectoryRef, rmCode);
            get(rmCodeRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const clientData = snapshot.val();
                        for (const client in clientData) {
                            clientArray.push(clientData[client])
                        }
                    } else {
                        console.log("No clients found for RM Code:", rmCode);
                    }
                }).then(() => {
                    setClients(clientArray)
                })
                .catch((error) => {
                    console.error("Error fetching client data:", error);
                });
        }
    }, [rmCode, database]);

    useEffect(() => {
        setProfit((sellingPrice - purchasePrice - brokerage).toFixed(4))
    }, [sellingPrice, purchasePrice, brokerage])

    useEffect(() => {
        setTotalProfit(((profit * bondAmount)/100).toFixed(4))
    }, [profit, bondAmount])

    const clientRef = ref(database, `clientDirectory/${rmCode}/${selectedPAN}/Investments/Bonds/${bondISIN}`)
    const submitInvestment = async () => {

        const clientBondInvestmentDetails = {
            bondISIN: `${bondISIN}`,
            bondName: `${bondName}`,
            bondAmount: `${bondAmount}`,
            purchasePrice: `${purchasePrice}`,
            sellingPrice: `${sellingPrice}`,
            brokerage: `${brokerage}`,
            profit: `${profit}`,
            // faceValue: `${faceValue}`,
            company: `${company}`,
            totalProfit: `${totalProfit}`,
            settlementDate: `${settlementDate}`,
            bondMaturityDate: `${bondMaturityDate}`,
            bondCallDate: `${bondCallDate}`,
            isVisible: "false"

        }
        if (bondISIN && selectedPAN ) {
            set(clientRef, clientBondInvestmentDetails).then(() => {
                setBondName("")
                setBondsISIN("")
                setBondAmount("")
                setPurchasePrice("")
                setSelectedPAN("")
                setBrokerage("")
                setProfit("")
                setSellingPrice("")
                // setFaceValue("")
                setBondQuantity("")
                setCompany("")
                setBondMaturityDate("")
                setBondCallDate("")
                setSettlementDate("")
                alert("Investment Added")
                navigate("/sales")
                
            }).catch((error) => {
                console.log(error)
            })
        } else {
            alert("Please select PAN and ISIN")
        }

    }

    useEffect(() => {
        const foundClient = clients.find((client) => client.clientPAN === selectedPAN);
        if (foundClient) {
            setClientEmail(foundClient.clientEmail)
            setClientName(foundClient.clientName);
        }
    }, [clients, selectedPAN, clientName]);
    
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Select PAN</label>

                            <select
                                className="form-control"
                                id="clientPANSelect"
                                name="clientPAN"
                                // value={selectedPAN || (clients.length > 0 ? clients[0].clientPAN : "")}
                                onChange={handlePANChange}
                                required
                            >
                                <option value="">-- Select Client PAN --</option>
                                {clients.map((client) => (
                                    <option key={client.clientPAN} value={client.clientPAN}>
                                        {client.clientPAN}
                                    </option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">ISIN</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name1"
                                placeholder="ISIN"
                                value={bondISIN}
                                required
                                onChange={(e) => setBondsISIN(e.target.value.toUpperCase())}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Call Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="name1"
                                placeholder="Call Date"
                                value={bondCallDate}
                                onChange={(e) => setBondCallDate(e.target.value)}
                                autoComplete="amount"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="purchasePrice">Purchase Price</label>
                            <input
                                type="number"
                                className="form-control"
                                id="purchasePrice"

                                placeholder="Purchase Price"
                                value={purchasePrice} // Replace "bondName" with your state variable for purchase price
                                onChange={(e) => setPurchasePrice(e.target.value)} // Update state variable for purchase price
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Company</label>
                            <select
                                className="form-control"
                                id="company" name="" onChange={(e) => { setCompany(e.target.value) }}>
                                <option value="">--Select Company--</option>

                                <option value="DCS">DCS</option>
                                <option value="DFS">DFS</option>
                                <option value="DS">DS</option>
                            </select>
                        </div>


                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Name</label>
                            <input
                            required
                                type="texgt"
                                className="form-control"
                                id="name1"
                                placeholder="Name"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value.toUpperCase())}
                                autoCapitalize="true"
                                autoComplete="name"
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Security Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name1"
                                placeholder="Security Name"
                                value={bondName}
                                onChange={(e) => setBondName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Maturity Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="name1"
                                placeholder="Maturity Date"
                                value={bondMaturityDate}
                                onChange={(e) => setBondMaturityDate(e.target.value)}
                                autoComplete="amount"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sellingPrice">Selling Price</label>
                            <input
                                type="number"
                                className="form-control"
                                id="sellingPrice"
                                placeholder="Selling Price"
                                value={sellingPrice}
                                onChange={(e) => setSellingPrice(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profit">Profit</label>
                            <input
                                type="number"
                                className="form-control"
                                id="profit"
                                placeholder="Profit"
                                value={profit}
                                onChange={(e) => setProfit(e.target.value)}
                                readOnly
                            />
                        </div>


                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Email</label>
                            <input
                                type="mail"
                                className="form-control"
                                id="name1"
                                placeholder="Email"
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Settlement Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="name1"
                                placeholder="Settlement Date"
                                value={settlementDate}
                                onChange={(e) => setSettlementDate(e.target.value)}
                                autoComplete="amount"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Amount</label>
                            <input
                                type="number"
                                className="form-control"
                                id="name1"
                                placeholder="Amount"
                                value={bondAmount}
                                onChange={(e) => setBondAmount(e.target.value)}
                                autoComplete="amount"
                            />
                        </div>
                        {
                            isAdmin === "true" && (
                                <div className="form-group">
                                    <label htmlFor="brokerage">Brokerage</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="brokerage"
                                        placeholder="Brokerage"
                                        value={brokerage}
                                        onChange={(e) => setBrokerage(e.target.value)}
                                        readOnly
                                    />
                                </div>
                            )
                        }


                    </div>

                </div>
                {/* Submit Button */}
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button
                            type="submit"
                            class="btn btn-primary btn-rounded btn-fw"
                            onClick={submitInvestment}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBonds