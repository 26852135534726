import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Employee from "./pages/Employee";
import Login from "./pages/Login";
import AddEmployess from "./pages/AddEmployee";
import Leave from "./pages/Leave";
import Sales from "./pages/Sales";
import AddClients from "./pages/AddClients";
import AddInvestment from "./pages/AddInvestment";
import ClientDetails from "./pages/clientDetails";
import EditInvestments from "./pages/EditBondInvestment";
import EditMF from "./pages/EditMFInvestment";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/employee-directory" element={<Employee />} />
          <Route path="/leave-request" element={<Leave />} />
          <Route path="add-employess" element={<AddEmployess />} />
          <Route path="sales" element={<Sales />} />
          <Route path="add-clients" element={<AddClients />} />
          <Route path="add-investments" element={<AddInvestment />} />
          <Route path="client-data" element={<ClientDetails />} />
          <Route path="investment-edit" element={<EditInvestments />} />
          <Route path="mf-edit" element={<EditMF />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
