import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { app } from "../pages/firebase";
import {
  getDatabase,
  ref,
  get,
  set,
  child,
  push,
  update,
  onValue,
} from "firebase/database";

function EmpTable({ employees }) {
  return (
    <tbody className="table">
      {employees.map((employee) => (
        <tr key={employee.empCode}>
          <td>{employee.empName}</td>
          <td>{employee.empCode}</td>
          <td>{employee.empDesignation}</td>
          <td>{employee.empDOJ}</td>
        </tr>
      ))}
    </tbody>
  );
}

export default EmpTable;