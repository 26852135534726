import { useState, useEffect, useCallback } from "react"
// import Navbar from "./Navbar"
// import SideBar from "./Sidebar"
// import Footer from "./Footer"
import { app } from "../pages/firebase"
import { getDatabase, ref, onValue, child, get, set } from "firebase/database"
import { isVisible } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";
// import { useSubmit } from "react-router-dom"
function AddFD() {
    const navigate = useNavigate()
    const database = getDatabase(app)
    const [selectedPAN, setSelectedPAN] = useState();
    let [clients, setClients] = useState([]);
    let [rmCode, setRMCode] = useState("")
    const [clientName, setClientName] = useState("")
    const [clientEmail, setClientEmail] = useState("")
    const [fdName, setFDName] = useState("")
    const [fdRate, setFDRate] = useState("")
    const [fdType, setFDType] = useState("")
    const [fdAmount, setFDAmount] = useState(0)
    const [fdStartDate, setFDStartDate] = useState("")
    const [fdDuration, setFDDuration] = useState(0)
    const [maturityDate, setMaturityDate] = useState();
    const [fdr, setFdr] = useState("")
    const [brokerage, setBrokerage] = useState("")
    useEffect(() => {
        getRMCode()
    })
    const handlePANChange = (event) => {
        setSelectedPAN(event.target.value);
    };

    function getRMCode() {
        let uid = window.localStorage.getItem("uid");
        const rmRef = ref(database, `empDirectory/${uid}`)
        onValue(rmRef, (snapshot) => {
            let data = snapshot.val()
            setRMCode(`${data.empCode}`)
        })
    }

    useEffect(() => {
        const clientArray = [];
        if (rmCode) {
            const clientDirectoryRef = ref(database, "clientDirectory");
            const rmCodeRef = child(clientDirectoryRef, rmCode);

            get(rmCodeRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const clientData = snapshot.val();
                        for (const client in clientData) {
                            clientArray.push(clientData[client])
                        }
                    } else {
                        console.log("No clients found for RM Code:", rmCode);
                    }
                }).then(() => {
                    setClients(clientArray)
                })
                .catch((error) => {
                    console.error("Error fetching client data:", error);
                });
        }
    }, [rmCode, database]);

    useEffect(() => {
        calculateBrokerage(fdAmount, fdDuration)
    }, [fdAmount, fdDuration])

    function calculateBrokerage(amount, tenure) {

    }

    useEffect(() => {
        const foundClient = clients.find((client) => client.clientPAN === selectedPAN);
        if (foundClient) {
            setClientName(foundClient.clientName);
            setClientEmail(foundClient.clientEmail)
        }
    }, [clients, selectedPAN]);
    useEffect(() => {
        if (fdStartDate && fdDuration) {
            let startDate = new Date(fdStartDate);
            let durationInMonths = parseInt(fdDuration, 10);
            startDate.setMonth(startDate.getMonth() + durationInMonths);
            setMaturityDate(startDate.toISOString().split('T')[0]);
        }
    }, [fdDuration, fdStartDate]);

    const baseBrokerage = {
        "0": 0,
        "12": 0.5,
        "18": 0.5,
        "24": 0.8,
        "30": 0.8,
        "36": 1.2,
        "42": 1.2,
        "50": 1.4,
        "60": 1.5
    }

    const additionalBrokerage = {
        "2500000-50000000": {
            "0": 0,
            "12": 0.2,
            "18": 0.2,
            "24": 0.25,
            "30": 0.25,
            "36": 0.6,
            "42": 0.6,
            "50": 0.6,
            "60": 0.6
        },
        "50000000": {
            "12": 0.25,
            "18": 0.25,
            "24": 0.3,
            "30": 0.3,
            "36": 0.65,
            "42": 0.65,
            "50": 0.65,
            "60": 0.65
        }
    }

    function calculateNetBrokerage(fdAmount, fdDuration, fdName) {
        if (fdName === "Shriram") {
            // Validate inputs (e.g., ensure fdAmount and fdDuration are positive numbers)
            if (fdAmount <= 0 || fdDuration <= 0) {
                return "Invalid inputs"; // Or handle the error differently
            }
            const baseRate = baseBrokerage[fdDuration];
            const additionalRateKey = fdAmount >= 50000000 ? "50000000" : "2500000-50000000";
            const additionalRate = additionalBrokerage[additionalRateKey][fdDuration];
            const netBrokerage = (baseRate + additionalRate)

            return netBrokerage;
        } else if (fdName === "Bajaj") {
            const brokerageData = {
                "brokerage": {
                    "under_5cr": {
                        0: 0,
                        12: 0.55,
                        24: 0.75,
                        36: 1.15,
                        48: 1.3,
                        60: 1.3
                    },
                    "over_5cr_50cr": {
                        0: 0,
                        12: 0.65,
                        24: 0.85,
                        36: 1.2,
                        48: 1.35,
                        60: 1.35
                    }
                }
            }
            let valueRange;
            if (fdAmount < 50000000) {
                valueRange = "under_5cr";
            } else if (fdAmount >= 50000000) {
                valueRange = "over_5cr_50cr";
            } else {
                throw new Error("Invalid amount");
            }
            const br = brokerageData.brokerage[valueRange][fdDuration];
            return br
        }

    }

    useEffect(() => {
        const calculatedBrokerage = calculateNetBrokerage(fdAmount, fdDuration, fdName);
        setBrokerage(calculatedBrokerage);
    }, [fdAmount, fdDuration, fdName]);

    useEffect(() => {
        const calculatedBrokerage = calculateNetBrokerage(fdAmount, fdDuration, fdName);
        setBrokerage(calculatedBrokerage);
    }, [fdAmount, fdDuration, fdName]);
    const clientRef = ref(database, `clientDirectory/${rmCode}/${selectedPAN}/Investments/FD/${fdr}`)
    const submitFD = async () => {
        if (selectedPAN && fdr) {
            const clientBondInvestmentDetails = {
                fdName: `${fdName}`,           // Name of the FD
                fdRate: `${fdRate}`,           // FD rate
                fdType: `${fdType}`,           // Type of FD (e.g., Cumulative or Non-Cumulative)
                fdAmount: `${fdAmount}`,       // FD amount invested
                fdStartDate: `${fdStartDate}`, // FD start date
                fdDuration: `${fdDuration}`,   // Duration of the FD in months
                maturityDate: `${maturityDate}`,// Calculated maturity date
                fdr: `${fdr}`,
                brokerage: `${brokerage}`,
                isVisible: "false"                // FDR code

            };
            set(clientRef, clientBondInvestmentDetails).then(() => {
                alert("FD added")
                setFdr("")
                setFDName("")
                setFDAmount("")
                setFDDuration("")
                setFDRate("")
                setMaturityDate("")
                setFDStartDate("")
                setFDType("")
                alert("Investment Added")
                navigate("/sales")
            }).catch((error) => {
                console.log(error)
            })
        } else {
            alert("Please select PAN and FDR")
        }
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Select PAN</label>

                            <select
                                className="form-control"
                                id="clientPANSelect"
                                name="clientPAN"
                                // value={selectedPAN || (clients.length > 0 ? clients[0].clientPAN : "")}
                                onChange={handlePANChange}
                                required
                            >
                                <option value="">-- Select Client PAN --</option>
                                {clients.map((client) => (
                                    <option key={client.clientPAN} value={client.clientPAN}>
                                        {client.clientPAN}
                                    </option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1"> FDR</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name1"
                                placeholder="Enter FDR"
                                value={fdr}
                                onChange={(e) => setFdr(e.target.value.toUpperCase())}
                                autoComplete=""

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Amount</label>
                            <input
                                type="number"
                                className="form-control"
                                id="name1"
                                placeholder="Amount"
                                value={fdAmount}
                                onChange={(e) => setFDAmount(e.target.value)}
                                autoComplete=""
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Duration</label>
                            <input
                                type="nubmer"
                                className="form-control"
                                id="name1"
                                placeholder="Months"
                                value={fdDuration}
                                onChange={(e) => setFDDuration(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Name</label>
                            <input
                                type="texgt"
                                className="form-control"
                                id="name1"
                                placeholder="Name"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value.toUpperCase())}
                                autoCapitalize="true"
                                autoComplete="PAN"
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Fund Name</label>
                            <select
                                className="form-control"
                                id="Investmenttype" name=""
                                onChange={(e) => { setFDName(e.target.value) }}
                            >
                                <option value="">--Select FD--</option>

                                <option value="Shriram">Shriram</option>
                                <option value="Bajaj">Bajaj</option>
                                <option value="PNB">PNB</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">FD Type</label>
                            <select
                                className="form-control"
                                id="Investmenttype" name=""
                                onChange={(e) => { setFDType(e.target.value) }}
                            >
                                <option value="">--Select FD Type--</option>

                                <option value="Shriram">Cummulative</option>
                                <option value="Bajaj">Non-Cummulative</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="name1">Maturity Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="name1"
                                placeholder="Matutiry"
                                value={maturityDate}
                                autoCapitalize=""
                                autoComplete=""
                                readOnly
                            />
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="name1">Email</label>
                            <input
                                type="mail"
                                className="form-control"
                                id="name1"
                                placeholder="Email"
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Rate</label>
                            <input
                                type="number"
                                className="form-control"
                                id="name1"
                                placeholder="FD rate"
                                value={fdRate}
                                onChange={(e) => setFDRate(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Start Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="name1"
                                placeholder="FD start Date"
                                value={fdStartDate}
                                onChange={(e) => setFDStartDate(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name1">Brokerage</label>
                            <input
                                type="number"
                                className="form-control"
                                id="name1"
                                placeholder="Brokerage"
                                value={brokerage}
                                readOnly
                            />
                        </div>

                    </div>

                </div>
                {/* Submit Button */}
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button
                            type="submit"
                            class="btn btn-primary btn-rounded btn-fw"
                            onClick={submitFD}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddFD